.report-table {
  font-size: 13px;
  height: 100vh;
  overflow: auto;

  table {
    text-align: left;
    position: relative;
    border-collapse: collapse;
  }

  th {
    position: sticky;
    top: 0;
    z-index: 10;

    &.row {
      top: 23px;
      padding: 5px 6px;
    }
  }

  td,
  th {
    text-align: left;
    padding: 3px 6px;
    white-space: nowrap;

    .slice__label {
      background-color: transparent;
      width: 18px;
      height: 18px;
      margin: 0;
      border-radius: 0;

      .fa-stack {
        position: relative;
        top: -3px;
        width: 18px;
        height: 18px;
        line-height: 18px;
        cursor: default;
      }
    }
  }

  a {
    font-size: 13px;
  }
}
