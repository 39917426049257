.costing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 260px;
  font-family: $font-bold;
  line-height: 1.1;
  height: 100%;
}

.costing .cost {
  margin-bottom: 5px;
  color: $grey-13;
}

.costing-table {
  clear: both;
  padding-top: 20px;
  width: 100%;
}

.costing-table td {
  padding: 10px 20px;
}
