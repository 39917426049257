tt-renamable-label {
  width: 100%;
  line-height: 1.1;

  .tt-renamable-label__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .tt-renamable-label__container--editable:hover {
    color: black;
  }

  .tt-renamable-label__container--disabled {
    color: #aaa;
    font-family: gilroy-light;
  }

  .tt-renamable-label__input-container {
    width: calc(100% - 25px);
    min-height: 24px;
    display: inline-flex;
    text-transform: none;
    align-items: center;
    width: 100%;
    padding: 3px 0;
  }

  .tt-renamable-label__input {
    width: 100%;
    height: 24px;
    padding: 2px 0;
    margin: 0;
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0px;
    font-size: 14px;
  }

  .fa:hover {
    color: black;
  }
}
