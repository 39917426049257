.pagebar {
  background-color: $white-1;
  height: 60px;
  // @todo dead css review - not found in modules
  &.pagebar__no-height {
    height: auto;
  }
}

.pagebar__container {
  max-width: 960px;
  margin: 0 auto;
  &:before,
  &:after {
    display: table;
    content: ' ';
  }
  &:after {
    clear: both;
  }
}

.pagebar__title,
.pagebar__actions {
  float: left;
  min-height: 1px;
  padding-top: 20px;
  position: relative;
}

.pagebar__title {
  padding-left: 20px;
  padding-right: 5px;
}

.pagebar__actions {
  padding-right: 20px;
  text-align: right;
  float: right;

  #customer-search {
    width: 250px;
    height: 37px;
    padding: 10px;
  }
}

.pagebar__breadcrumb {
  color: $grey-8;
  padding-left: 20px;
  display: inline-block;
  @include font-size(0.8);
  font-weight: normal;
  letter-spacing: 1px;
  @include line-height(3);
  text-transform: uppercase;

  .fa {
    margin-left: 3px;
    margin-right: 3px;
  }
}

.portal {
  .pagebar {
    height: 25px;
    background-color: transparent;
  }

  .pagebar--alt {
    border-bottom: none;
    height: 60px;
  }

  .pagebar__container {
    max-width: 1200px;
  }

  // @todo dead css review - not found in modules
  .pagebar.pagebar__no-height {
    height: auto;
  }

  .pagebar__title,
  .pagebar__actions {
    padding-top: 14px;
  }

  .pagebar__nav {
    float: left;
    padding-top: 0;
  }

  .grid__col--6 {
    .pagebar__actions {
      padding: 0;
    }
  }
}

.customer__list {
  .portal {
    .pagebar__actions {
      margin-top: -50px;
      z-index: 10;
      position: relative;
    }
  }
}
.add-customer-link {
  cursor: pointer;
}
