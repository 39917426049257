input {
  @include font-size(1);
  color: $grey-11;
  font-family: $font-light;
  font-weight: normal;
  line-height: normal;
  margin: 0;
  text-align: right;
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='number'],
input[type='search'],
input[type='tel'],
input[type='url'] {
  background-color: white;
  border-radius: 5px; /* to match Chrome's default select styling */
  border: 1px solid $grey-13;
  box-shadow: none;
  height: 34px;
  outline: none;
  padding: 6px 8px;
  text-align: left;

  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-shadow: none;

  &.active,
  &:active,
  &.focus {
    border: 1px solid $black-1;
  }
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}

.button--upload {
  input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 125px;
  }
}

.glass__content.control input {
  padding: 3px 5px;
}

.product-radios label {
  cursor: pointer;
}

.product-radios {
  input[type='radio'] {
    cursor: pointer;
  }
  input[type='radio']:checked {
    cursor: default;
  }
  input[type='radio']:checked + label {
    font-family: $font-bold;
    cursor: text;
  }
}

.quote__description {
  input {
    @include font-size(1);
  }
}

.quote__items--financial {
  input {
    text-align: right;
  }
}

.quote__items--financial-cost input {
  color: $grey-10;
}

/* TODO: Replace with universal form field styling */
.input--text {
  padding: 4px 6px;
  border-radius: 3px;
  border: 1px solid $white-7;
}

.input-lookalike {
  border: 1px solid #aaa;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 10px;
}

.retailer__list--search {
  width: 100%;
  margin-top: 15px;
}

// Remove blue x from select2 search
.select2-search input[type='search']::-webkit-search-decoration,
.select2-search input[type='search']::-webkit-search-cancel-button,
.select2-search input[type='search']::-webkit-search-results-button,
.select2-search input[type='search']::-webkit-search-results-decoration {
  display: none;
}

// fix select2 search padding
.select2-search input {
  padding: 4px 20px 4px 5px !important;
}

.radio__label {
  display: inline-block;
  padding-left: 10px;
  max-width: 90%;
  vertical-align: top;
}
