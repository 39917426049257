h1 {
  @include font-size(2);
  @include header-base();
  font-family: $font-bold;
}

h1.heading-1 {
  font-family: gilroy-extra-bold;
  margin: 12px 0 18px;
}

.pagebar__title {
  h1 {
    @include font-size(1.375);
    @include line-height(2.313);
  }
}
