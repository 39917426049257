.card {
  display: block;
  background-color: white;
  border: none;
  border-radius: 3px;
  padding: 0;
  position: relative;
  width: 100%;
}

.card__list {
  margin: 0 0 10px 0;
  padding: 0;
  width: 100%;
  &:before,
  &:after {
    display: table;
    content: ' ';
  }
  &:after {
    clear: both;
  }
}

.card__list--quote-pricing dd {
  text-align: right;
}

.card--product {
  height: 228px;
  padding: 0;
  overflow: hidden;
}

.card--image {
  height: 200px;
  min-height: 320px;
  padding: 0;
  overflow: hidden;
  img {
    max-height: 200px;
    max-width: 100%;
  }
}

.card__image {
  height: 175px;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white-8;
  img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.card--product {
  // @todo this has been done wrong needs further research https://developer.mozilla.org/en-US/docs/Web/CSS/radial-gradient
  .card__image {
    background: -moz-radial-gradient(center, circle cover, #3498db 0%, #2980b9 100%);
    background: -webkit-gradient(
      radial,
      center center,
      0,
      center center,
      100%,
      color-stop(0%, #3498db),
      color-stop(100%, #2980b9)
    );
    background: -webkit-radial-gradient(center, circle cover, #3498db 0%, #2980b9 100%);
    background: -o-radial-gradient(center, circle cover, #3498db 0%, #2980b9 100%);
    background: -ms-radial-gradient(center, circle cover, #3498db 0%, #2980b9 100%);
    background: radial-gradient(center, circle cover, #3498db 0%, #2980b9 100%);
    background-color: $blue-1;
    height: 146px;
    overflow: hidden;
  }
  .card__title {
    background-color: $white-1;
    height: 54px;
    overflow: hidden;
    padding: 10px 15px;
    color: $grey-1;
    @include font-size(0.875);
  }
}

// @todo dead css review - not found in modules
.card--pricing {
  width: 167px;
  padding: 4px;
  float: right;
  margin-left: 20px;
}

// @todo dead css review - not found in modules
.card__list--pricing {
  margin-bottom: 0;
}

// @todo dead css review - not found in modules
.card--reference {
  background-color: $white-4;
}

.card--heading {
  margin-top: 40px;
}

.card__heading {
  margin: 0 0 20px 0;
  padding: 0;
  &:after {
    clear: both;
  }
  &:before,
  &:after {
    display: table;
    content: ' ';
  }
}

.portal {
  .card {
    background-color: $white-1;
  }
  .card--details,
  .portal .card--image {
    min-height: 320px;
  }
  .card--heading {
    min-height: 350px;
  }
}

.page__order {
  .card {
    overflow-wrap: break-word;
  }
}

.page__order {
  .card--totals {
    dt {
      padding: 10px 20px 10px 0;
      @include font-size(1.1);
      font-family: $font-bold;
      color: $grey-12;
    }
    dd {
      font-family: $font-bold;
      padding: 10px 20px;
      text-align: right;
      background: $white-8;
      width: auto;
      float: right;
    }
  }
  .card h3 {
    font-family: $font-bold;
    margin-bottom: 10px;
    padding-top: 10px;
  }
  .card__custom-item-warning {
    padding-top: 10px;
  }
}

.orders-placed__manufacturer {
  .card {
    background: #fafafa;
    padding: 20px;
    margin-bottom: 20px;
  }
  .card--totals dt {
    @include font-size(1);
  }
  .card__actions {
    text-align: center;
  }
}

.visualiser {
  .card {
    height: auto;
    min-height: auto;
    border-radius: 3px;
    border: 1px solid $grey-9;
    &:hover {
      .card__image {
        background: $grey-9;
      }
    }
  }
  .card__actions {
    float: right;
    .fa-stack {
      display: inline-flex;
      align-items: center;
    }
  }
  .card__image {
    transition: all 0.3s ease-in-out;
  }
  .card__title {
    background-color: $white-1;
    min-height: 54px;
    overflow: hidden;
    padding: 15px;
    color: $grey-1;
    @include font-size(0.875);
    .card__title--custom {
      display: flex;
      justify-content: space-between;
    }
    & .custom-input {
      width: 85%;
    }
    .card--actions-fields {
      margin-top: 10px;
    }
  }
  & .loading--action {
    display: flex;
    align-items: center;
    & .spinner--item {
      margin-top: 0;
    }
  }
}

.custom--image {
  background: #efefef;
  height: auto;
  min-height: auto;
  border: 1px solid #dcdde0;
  border-radius: 3px;
  & .card__title {
    min-height: 54px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.card__image {
  .custom-img,
  .custom-img img {
    height: 175px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

#upload-modal {
  .visualiser {
    .card__title {
      padding: 10px 5px;
    }
    .custom-input {
      width: 75%;
    }
    .card--actions-fields {
      display: none;
    }
  }
}

.card__image_custom {
  overflow: hidden;
  position: relative;
  animation-duration: 1.7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderAnimate;
  background: linear-gradient(to right, #eee 2%, #fff 20%, #eee 33%);
  background-size: 1000px;
}

@keyframes placeholderAnimate {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
}
