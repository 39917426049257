.skew-image {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
}

.skew-image__control-point {
  background-color: $white-1;
  height: 30px;
  width: 30px;
  -webkit-box-shadow: 0 0 10px 4px $black-1;
  -moz-box-shadow: 0 0 10px 4px $black-1;
  box-shadow: 0 0 10px 4px $black-1;
  cursor: move;
  position: absolute;
  z-index: 30;
  border-radius: 15px;
}

.skew-image__control-delete {
  position: absolute;
  top: -10px;
  left: -40px;
  z-index: 30;
  -webkit-box-shadow: 0 0 7px 2px $black-1;
  -moz-box-shadow: 0 0 7px 2px $black-1;
  box-shadow: 0 0 7px 2px $black-1;
  background-color: $black-1;
  width: 25px;
  padding-top: 4px;
  @include font-size(1.563);
}

.skew-image__control-delete span {
  color: $white-1;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari*/
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
