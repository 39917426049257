.spinner__message {
  line-height: 42px;
  @include line-height(2.625);
  padding-left: 5px;
}

.spinner--item {
  @include font-size(1.8);
  margin-top: 3px;
}

.spinner--order-status {
  margin-left: -21px;
  margin-right: 5px;
  width: 16px;
  height: 16px;
  padding-top: 1px;
  text-align: center;
  line-height: 1rem;

  &.ng-animate {
    animation: 0s none;
    transition: 0s none;
  }
}

.spinner--center {
  text-align: center;
  padding-top: 20px;
}
