.fade {
  -webkit-transition: 500ms linear all;
  transition: 500ms linear all;
  opacity: 1;
  &.ng-hide-add.ng-hide-add-active,
  &.ng-hide-remove.ng-hide-remove-active {
    opacity: 1;
  }
  &.ng-hide {
    opacity: 0;
  }
}
