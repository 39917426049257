.text-block {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  line-height: 1.8;
  p {
    line-height: 1.8;
    margin-bottom: 10px;
  }
  ul,
  ol {
    margin: 0 0 10px 0;
    padding-left: 20px;
    li {
      padding-left: 20px;
    }
  }
  h2 {
    text-transform: capitalize;
  }
  h3 {
    margin-top: 20px;
    text-transform: capitalize;
  }
}
