.checkbox {
  margin: 20px;
  &__group {
    margin-bottom: 40px;
  }
  & input {
    padding: 6px 8px 6px 20px;
  }
  .portal__label {
    margin-right: 20px;
    &--inline {
      margin-right: 0;
    }
  }
}

.checkbox--in-line-with-buttons {
  display: inline-block;
  line-height: 35px;
  margin-right: 10px;
}
