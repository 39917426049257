.dropdown {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.dropdown.open .dropdown__menu {
  display: block;
}

.dropdown__menu--scrollable {
  max-height: 400px;
  overflow: auto;
}

.dropdown__menu {
  background-color: $white-1;
  background-clip: padding-box;
  border: 1px solid $grey-9;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  display: none;
  float: left;
  left: auto;
  list-style: none;
  margin: 2px 0 0;
  min-width: 160px;
  @include font-size(1);
  padding: 10px 0;
  position: absolute;
  text-align: left;
  top: 100%;
  -webkit-background-clip: padding-box;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  z-index: 1000;
}

.dropdown__menu.dropdown__menu--right {
  right: 0;
}

.dropdown--open > .dropdown-menu {
  display: block;
}

.dropdown__divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: $white-7;
}

.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  color: $white-1;
  background-color: $blue-7;
  border-color: $blue-8;
}

.btn-info:active:hover,
.btn-info.active:hover,
.open > .dropdown-toggle.btn-info:hover,
.btn-info:active:focus,
.btn-info.active:focus,
.open > .dropdown-toggle.btn-info:focus,
.btn-info:active.focus,
.btn-info.active.focus,
.open > .dropdown-toggle.btn-info.focus {
  color: $white-1;
  background-color: $blue-8;
  border-color: $blue-9;
}

.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  background-image: none;
}
