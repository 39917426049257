.alert {
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 20px;
  @include line-height(2.188);
}

.alert--warning {
  background-color: $orange-1;
  color: $white-1;
}

.alert--success {
  background-color: $green-1;
  color: $white-1;
}

.modal .alert {
  @include line-height(1);
}

.upload__error {
  float: left;
  @include line-height(2.313);
  color: $red-2;
}

.size-warning {
  clear: both;
  background: $yellow-1;
  text-align: center;
  padding: 30px;
  @include font-size(2);
  font-family: $font-bold;
}

@media (min-width: 920px) {
  .size-warning {
    display: none;
  }
}
