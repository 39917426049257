.page-title-holder {
  padding: 0 10px;
  margin: 20px 0;
}

.customer__list .page-title-holder {
  padding-left: 20px;
}

.page-title {
  font-family: $font-bold;
  @include font-size(1.25);
  text-transform: uppercase;
  margin: 0 0 0;
}

.page-title__actions {
  height: 57px;
}

.page__order .page-title-holder {
  margin-bottom: 8px;
}

.compositor__controls .page-title {
  margin-right: 20px;
}
