.checkatrade_card {
  border: 1px solid black;
  position: relative;
  margin-top: 15px;
}
.checkatrade_card:after {
  content: '';
  width: 150px;
  height: 42px;
  background-image: url(../images/checkatrade_logo.png);
  position: absolute;
  top: -16px;
  left: 28px;
  z-index: 1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
}

.checkatrade_card_content {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-left: 10px;
}

.checkatrade_card_text {
  align-self: center;
}

.checkatrade_checkbox {
  margin: 30px 20px 20px 20px;
}
