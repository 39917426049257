h3 {
  @include header-base();
  font: inherit;
}

h3.heading-3 {
  font-family: $font-bold;
  font-size: 18px;
  margin: 12px 0 8px;
}

.pricing-table {
  h3 {
    font-family: $font-bold;
    @include font-size(1.25);
    font-weight: normal;
    text-transform: uppercase;
    padding: 20px;
    margin: -20px -20px 50px -20px;
    background-color: $white-8;
    @include background-linear-gradient($white-1, $white-8);
    span {
      display: block;
      font: bold 25px/100px $font-extra-bold;
      color: $grey-19;
      background: $white-1;
      border: 5px solid $white-1;
      height: 100px;
      width: 100px;
      margin: 10px auto -65px;
      -moz-border-radius: 100px;
      -webkit-border-radius: 100px;
      border-radius: 100px;
      -moz-box-shadow:
        0 5px 20px $grey-16 inset,
        0 3px 0 $grey-5 inset;
      -webkit-box-shadow:
        0 5px 20px $grey-16 inset,
        0 3px 0 $grey-5 inset;
      box-shadow:
        0 5px 20px $grey-16 inset,
        0 3px 0 $grey-5 inset;
    }
  }
  .primary {
    h3 {
      background-color: $grey-16;
      @include background-linear-gradient($white-8, $grey-16);
      margin-top: -30px;
      padding-top: 30px;
      -moz-border-radius: 5px 5px 0 0;
      -webkit-border-radius: 5px 5px 0 0;
      border-radius: 5px 5px 0 0;
    }
  }
  .bronze {
    h3 {
      background-color: $orange-4;
      @include background-linear-gradient($orange-2, $orange-3);
    }
  }
  .silver {
    h3 {
      background-color: $orange-4;
      @include background-linear-gradient($grey-17, $grey-18);
    }
  }
  .gold {
    h3 {
      background-color: $yellow-2;
      @include background-linear-gradient($yellow-2, $yellow-3);
    }
  }
  .plan {
    &:nth-child(1) {
      h3 {
        -moz-border-radius: 5px 0 0 0;
        -webkit-border-radius: 5px 0 0 0;
        border-radius: 5px 0 0 0;
      }
    }
    &:nth-child(4) {
      h3 {
        -moz-border-radius: 0 5px 0 0;
        -webkit-border-radius: 0 5px 0 0;
        border-radius: 0 5px 0 0;
      }
    }
  }
}
