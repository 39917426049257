.orders-placed__customer h3 {
  @include font-size(1.2);
  border-top: 1px solid $white-5;
  padding-top: 30px;
}

.orders-placed__customer .address {
  white-space: pre;
}

.order__status,
.order__manufacturer {
  font-family: $font-bold;
}

.order__details {
  dt {
    font-family: gilroy-light;
    text-transform: none;
  }

  dd {
    font-family: $font-light;
  }
}

dd.order__important-row {
  font-family: $font-bold;
}

dt.order__important-row {
  font-family: gilroy-extra-bold;
}

.order-part__title {
  padding-top: 0;
  margin-bottom: 20px;
  font-family: gilroy-bold;
  font-size: 19px;
}
