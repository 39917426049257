.heading-with-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 12px;
  &.custom--button {
    margin: 0;
  }
  h1.heading-1,
  h2.heading-2,
  h3.heading-3,
  h4.heading-4 {
    margin-top: 0;
    margin-bottom: 0;
  }
  &.pt-0 {
    padding-top: 0;
  }
  &.mt-0 {
    margin-top: 0;
  }
}
.pricing--arrow.hide-arrow i {
  transform: rotate(-180deg);
}
.pricing--arrow.show-arrow i {
  transform: rotate(0deg);
}
