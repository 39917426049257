$black-1: #000000; // used for .navigation__item

$blue-1: #2980b9; // used for a, dd > a, p > a
$blue-2: #46a9ea; // used for .view--configure
$blue-3: #2c3e50; // used for .button--nav, .button--navbar
$blue-4: #45a4ec; // used for .button--support
$blue-5: #1f7ec6; // used for .button--support
$blue-6: #aed6f1; // used for .card__list--pricing
$blue-7: #31b0d5; // used for .dropdown-toggle
$blue-8: #269abc; // used for .dropdown-toggle
$blue-9: #1b6d85; // used for .dropdown-toggle
$blue-10: #3498db; // used for .control__option
$blue-11: #5bc0de; // used for .btn-info
$blue-12: #46b8da; // used for .btn-info

$green-1: #27ae60; // used for .button--primary, .button--upload, .button__visualiser-green, .alert--success
$green-2: #208e4e; // used for .button--primary:hover
$green-3: #145b32; // used for .button--upload

$grey-1: #4a4a4a; // used for a, body, input, textarea, select, border-bottom & color .card__heading h2, .button--secondary, .button--tertiary, .navigation__item
$grey-2: #a6a6a6; // used for .portal .footbar p | border-color for .button--tertiary
$grey-3: #979797; // used for .button--tertiary
$grey-4: #bfbfbf; // used for .button--nav
$grey-5: #999999; // used for .button--nav, .onboarding__link
$grey-6: #333333; // used for .button-cancel, .button--additem
$grey-7: #979797; // used for .navigation__item
$grey-8: #888888; // used for .pagebar__breadcrumb
$grey-9: #cccccc; // used for .card__image
$grey-10: #808080; // used for .quote__items--financial-cost
$grey-11: #222222; // used for input
$grey-12: #555555; // used for .onboarding
$grey-13: #aaaaaa; // used for .page__quote
$grey-14: #798d8f; // used for slice
$grey-15: #95a5a6; // used for slice
$grey-16: #dddddd; // used for pricing_table
$grey-17: #c2cdcf; // used for pricing_table
$grey-18: #b9c0c3; // used for pricing_table
$grey-19: #777777; // used for pricing_table

$orange-1: #e67e22; // used for .slice, .summary__highlight, .alert--warning
$orange-2: #e29159; // used for pricing_table
$orange-3: #d98b57; // used for pricing_table
$orange-4: #cd7f32; // used for pricing_table

$red-1: #e74c3c; // used for .fa-red
$red-2: #c0392b; // used for .control__text--warning, .slice__price--warning

$white-1: #ffffff; // used as base white
$white-2: #fafafa;
$white-3: #ebebed; // used for .view--draw
$white-4: #f0f1f3; // used for .dropdown__menu a, .button--navbar
$white-5: #efefef; // used for .footer .credits a
$white-6: #dcdde0; // used for .group__heading, .view__spacer
$white-7: #d8d8d8; // used for .button--secondary, .button--tertiary
$white-8: #eeeeee; // used for .button--nav
$white-9: #dbd8d3; // used for .navigation__item, .onboarding__progress
$white-10: #fae6d3; // used for .slice__price
$white-11: #f2f1ef;
$white-12: #f8f8f8; // used for product item

$yellow-1: #f9bf3b; // used for .size-warning
$yellow-2: #febc2e; // used for pricing_table
$yellow-3: #f8ad2d; // used for pricing_table

@mixin background-linear-gradient($from, $to) {
  background-image: -moz-linear-gradient($from, $to);
  background-image: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
  background-image: -webkit-linear-gradient($from, $to);
  background-image: -o-linear-gradient($from, $to);
  background-image: -ms-linear-gradient($from, $to);
  background-image: linear-gradient($from, $to);
}
