.slice {
  background-color: $white-1;
  border: 1px solid $white-6;
  border-radius: 3px;
  display: flex;
  height: 60px;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  &:before,
  &:after {
    display: table;
    content: ' ';
  }
  &:after {
    clear: both;
  }
  &.quote__status {
    height: 40px;
  }
  &.slice__custom {
    height: 75px;
  }
}
.slice--alt {
  border-color: $white-4;
  background-color: $white-4;
}
.slice--small {
  height: 44px;
}
.slice--flexiheight {
  height: auto;
}
.slice__image,
.slice__details {
  float: left;
}
.slice__details {
  padding: 7px 10px;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
}
.slice__action {
  float: right;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 56px;
  .button__visualiser-divider {
    display: none;
  }
  &.slice-percentage input {
    padding: 6px 18px 6px 8px;
  }
  & .custom__action {
    display: block;
  }
}
.slice__content:before,
.slice__content:after {
  display: table;
  content: ' ';
}
.slice__content:after {
  clear: both;
}
.slice__image {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $white-7;
  margin-right: 10px;
  margin-top: 2px;
  outline: none;
  padding: 2px;
  height: 40px;
  width: 40px;
  text-align: center;
  &.slice__image--finalising,
  &.slice__image--finalised,
  &.slice__image--sent,
  &.slice__image--viewed {
    border: 1px solid $orange-1;
    .spinner--item {
      color: $orange-1;
    }
  }
  &.slice__image--confirmed {
    border: 1px solid $blue-10;
    .spinner--item {
      color: $blue-10;
    }
  }
  img {
    max-width: 34px;
    max-height: 34px;
  }
}
.slice__image--custom-item {
  width: 34px;
  height: 34px;
  font-family: gilroy-extra-bold;
  font-size: 24px;
  padding-top: 2px;
  color: grey;

  .material-icons {
    font-size: 28px;
    margin-top: 1px;
  }
  &.slice--finalising,
  &.slice--finalised,
  &.slice--sent,
  &.slice--viewed {
    color: $orange-1;
  }
  &.slice--confirmed {
    color: $blue-10;
  }
}
.slice__pricing {
  border-left: 1px solid $white-6;
  padding: 9px;
  text-align: center;
  width: 165px;
  flex-grow: 0;
}
.slice__price {
  height: 40px;
  padding: 4px 4px 2px 4px;
  width: 146px;
  @include line-height(1.25);
  & .selected_activeUser {
    width: 146px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none;
  }
}
.slice__price--warning {
  color: $red-2;
}
.slice__summary {
  float: right;
  text-align: right;
  padding: 20px 10px 0;
  @include line-height(1.375);
}
.slice__title {
  @include font-size(1);
  font-family: $font-bold;
  line-height: 25px;
  @include line-height(1.563);
  padding-top: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.slice__description {
  @include font-size(0.75);
  font-family: $font-light;
  @include line-height(1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: none;
  strong {
    font-family: $font-bold;
  }
}
.slice__question {
  @include font-size(1);
  font-family: $font-bold;
  line-height: 25px;
  @include line-height(1.563);
  padding-top: 2px;
}
.slice__answer {
  p {
    margin-bottom: 10px;
    a {
      margin-bottom: 10px;
    }
  }
}
.slice__label {
  display: block;
  flex-shrink: 0;
  background-color: $white-7;
  width: 35px;
  float: left;
  margin-top: -1px;
  margin-bottom: -1px;
  margin-left: -1px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  color: $white-1;
  text-align: center;
  @include font-size(1.125);
  &.slice--custom {
    height: 30px;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    color: white;
    font-size: 18px;
    border-radius: 7px;
    -webkit-text-stroke-width: medium;
  }
  & .fa-stack {
    display: flex;
    align-items: center;
  }
  &.custom__label {
    display: flex;
    align-items: center;
  }
}
.slice--draft .slice--count,
.slice--needsReprice .slice--count,
.slice--reprice .slice--count,
.slice--repricing .slice--count {
  background: grey;
  &.custom__label {
    height: 75px;
    display: flex;
    align-items: center;
  }
}

.retailer__select--dropdown {
  border: none;
  cursor: pointer;
  font-family: gilroy-light;
  white-space: nowrap;
  font-size: 16px;
  font-size: 1rem;
  height: 34px;
  outline: 0;
  overflow: hidden;
  padding: 6px 12px;
  text-decoration: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  .glyphicons-chevron-down {
    margin-left: 16px;
  }
}
.slice__label--ok {
  background-color: $green-1;

  .slice__icon {
    color: $green-1;
  }
}
.slice__label--info {
  background-color: $blue-10;
}
.slice__label--info-alt {
  background-color: $orange-1;
}
.slice__label--warning {
  background-color: $red-2;

  .slice__icon {
    color: $red-2;
  }
}
.slice__label--default {
  background-color: $grey-1;
}
.slice__label--right {
  float: right;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  position: absolute;
  right: 0;
}
.slice__details--full {
  width: 100%;
}
.slice--orderitem {
  position: relative;
}
.slice--orderitem .slice__details {
  width: 100%;
}
.slice--orderitem .slice__action {
  right: 0;
  position: absolute;
}
.customer__list {
  .slice__summary {
    padding-top: 10px;
  }
}
.slice__icon {
  color: $white-7;
  @include font-size(0.9);
  &.rf-spin {
    // Needed, as without it will spin off center
    font-size: 13px;
  }
}
.slice--count {
  padding: 3px 8px;
  color: #fff;
  border-radius: 5px;
  font-family: gilroy-bold;
  display: block;
  letter-spacing: 0.1em;
}

.slice--finalising .slice__icon,
.slice--finalised .slice__icon,
.slice--sent .slice__icon,
.slice--viewed .slice__icon {
  color: $orange-1;
}
.slice--finalising .slice__label,
.slice--finalised .slice__label,
.slice--sent .slice__label,
.slice--viewed .slice__label,
.slice--finalising .slice--count,
.slice--finalised .slice--count,
.slice--sent .slice--count,
.slice--viewed .slice--count {
  background-color: $orange-1;
}
.slice--confirmed {
  .slice__icon {
    color: $blue-10;
  }
  .slice__label,
  .slice--count {
    background-color: $blue-10;
  }
}
.slice--expired {
  .slice__icon {
    color: $red-1;
  }
  .slice__label,
  .slice--count {
    background-color: $red-1;
  }
}
.slice__quote .tt-quote-actions {
  display: flex;
  align-items: center;
}
.slice__archive {
  background-color: #dbd8d346;
}
.slice__cost {
  margin-right: 12px;
}
.slice__cost div {
  float: right;
}
.slice__cost .summary__total {
  clear: both;
}
.slice__order .slice__cost {
  padding-top: 6px;
}

.slice__pending-retailer .slice__icon {
  font-size: 11px;
}

.slice__action table {
  margin-bottom: 0;
  border-bottom: none;

  tr,
  tr:nth-child(even),
  tr:hover {
    background: none;
    transition: none;
    cursor: auto;
  }

  td {
    width: auto;
    position: relative;
  }
}

.slice__action input {
  max-width: 100px;
}

.slice__link-with-content {
  flex-grow: 1;
  display: flex;
}

.slice__clickable {
  cursor: pointer;
}
.symbol__input {
  position: relative;
  display: inline;
}
.symbol-slice {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  &.currency-symbol {
    left: 7px;
    &.pricing-setting {
      left: 10px;
    }
  }
  &.percentage-symbol {
    right: 12px;
  }
  &.slice__action__percentage {
    right: 58px;
    bottom: 6px;
  }
}
