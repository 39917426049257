.option-card {
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid $white-7;
  color: $grey-1;
  display: block;
  position: relative;
  width: 100%;

  &:hover {
    background-color: #f7f7f7;
  }
}

.option-card--selected {
  background-color: #f7f7f7;

  &:hover {
    background-color: #f1f1f1;
  }
}

.option-card__corner-checkbox {
  position: absolute;
  top: 10px;
  left: 10px;
}

.option-card__image-container {
  align-items: center;
  border-bottom: 1px solid $white-7;
  display: flex;
  height: 125px;
  justify-content: center;
  padding: 12px;
}

.option-card__image {
  max-width: 100%;
  max-height: 100%;
}

.option-card__caption {
  padding: 8px 10px;
  font-size: 12px;
  line-height: 1.3;
  min-height: 52px;
  word-break: break-word;
}
