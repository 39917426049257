.summary__highlight {
  background-color: $orange-1;
  color: $white-1;
  border-radius: 3px;
  @include font-size(0.75);
  font-style: normal;
  @include line-height(0.75);
  padding: 2px 4px;
  margin-left: 5px;
  vertical-align: middle;
}
