.control__option {
  transition:
    border-color 200ms ease-in-out,
    color 200ms ease-in-out;
  background-color: $white-1;
  border: 1px solid $white-7;
  border-radius: 3px;
  cursor: pointer;
  display: table-cell;
  height: 95px;
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
  vertical-align: middle;
  color: $grey-7;
  text-align: center;
  &:hover {
    color: $grey-1;
  }
  input[type='radio'] {
    position: absolute;
    z-index: -1;
  }
  img {
    margin-bottom: 18px;
    border: 1px solid $white-6;
  }
  input[type='radio']:checked + label {
    background-color: $blue-6;
    border: 2px solid $blue-10;
    color: $grey-1;
    padding: 19px;
  }
  input[type='radio']:checked + label img {
    border: 1px solid $white-1;
  }
}
