.help {
  &__intro {
    .page-title {
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 10px;
      a {
        font-family: $font-bold;
        color: #333;
        border-bottom: 3px solid $white-9;
        margin-right: 10px;
        &:hover {
          text-decoration: none;
          transition: all 0.3s ease-in;
          border-bottom: 3px solid $grey-6;
        }
      }
    }
  }
  .slice {
    max-width: 768px;
  }
}
