a {
  border: 0;
  color: $grey-1;
  font-family: $font-light;
  @include font-size(1);
  font-weight: normal;
  margin: 0;
  padding: 0;
  text-decoration: none;
  vertical-align: baseline;
  &:active,
  &:hover {
    text-decoration: none;
  }
}

dd,
p {
  a {
    color: $blue-1;
    &:active,
    &:hover {
      text-decoration: underline;
    }
  }
}

.alert {
  a {
    color: $white-1;
    text-decoration: underline;
    &:active,
    &:hover {
      text-decoration: underline;
    }
  }
}

.customer__list {
  .segmentation__control {
    a {
      @include font-size(0.9375);
    }
  }
}

.dropdown__menu {
  a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    @include line-height(2);
    color: $grey-1;
    text-decoration: none;
    white-space: nowrap;
    &:active,
    &:focus,
    &:hover {
      color: $grey-1;
      text-decoration: none;
      background-color: $white-4;
    }
  }
}

.footer {
  .credits {
    a {
      color: $white-5;
      -webkit-transition: all 0.3s ease-in;
      -moz-transition: all 0.3s ease-in;
      -o-transition: all 0.3s ease-in;
    }
  }
}

.pagebar__breadcrumb {
  a {
    @include font-size(0.8);
    @include line-height(3);
    &:hover {
      text-decoration: underline;
    }
  }
}

.portal {
  // @todo dead css review - can't find footbar
  .footbar {
    a {
      color: $grey-2;
      @include font-size(0.875);
      padding-left: 20px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.quote__description {
  a {
    @include font-size(1);
  }
}

.segmentation__control {
  a {
    @include font-size(1.125);
  }
}
