/* Border-Box http:/paulirish.com/2012/box-sizing-border-box-ftw/ */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  min-width: 768px;
  padding: 0;
  position: relative;
}

html,
body,
div,
span,
applet,
object,
iframe,
blockquote,
pre,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

em {
  font: inherit;
}

audio,
canvas,
img,
video {
  vertical-align: middle;
}

button,
optgroup {
  margin: 0;
}

html,
button {
  color: $grey-11;
}

body {
  font-family: $font-light;
  @include font-size(1);
  font-weight: normal;
  color: $grey-1;
}

sup {
  vertical-align: super;
}

.clear:before,
.clear:after {
  content: '';
  display: table;
}

.clear:after {
  clear: both;
}

.clear {
  clear: both;
  zoom: 1;
}

.text-right {
  text-align: right;
}

.text-bottom {
  vertical-align: bottom !important;
}

.text-center {
  text-align: center;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.display-inline-block {
  display: inline-block;
}

.red {
  color: $red-1;
}

.vertically-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-button {
  text-align: right;
}

.top-margin {
  margin-top: 15px;
}
