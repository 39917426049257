.summary__total {
  @include font-size(0.75);
  font-family: $font-bold;
  @include line-height(1);
}

// @todo dead css review - not found in modules
.quotes-summary {
  .summary__total {
    display: inline;
  }
  .summary__total:nth-child(2) {
    border-right: 1px solid $grey-12;
    padding-right: 3px;
  }
}
