.pricing-table {
  margin: 40px auto;
  text-align: center;
  width: 748px;
  .plan {
    background: $white-1;
    border: 1px solid $grey-16;
    color: $grey-6;
    padding: 20px;
    width: 248px; /* plan width = 180  20  20  1  1 = 222px */
    float: left;
    position: relative;
    opacity: 0.8;
    transition: all 0.3s ease-in-out;
    &:hover {
      opacity: 1;
    }
  }
  .primary {
    z-index: 2;
    top: -40px;
    border-width: 3px;
    padding: 30px 20px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-box-shadow:
      20px 0 10px -10px rgba(0, 0, 0, 0.15),
      -20px 0 10px -10px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow:
      20px 0 10px -10px rgba(0, 0, 0, 0.15),
      -20px 0 10px -10px rgba(0, 0, 0, 0.15);
    box-shadow:
      20px 0 10px -10px rgba(0, 0, 0, 0.15),
      -20px 0 10px -10px rgba(0, 0, 0, 0.15);
    opacity: 1;
  }
  .plan {
    &:nth-child(1) {
      -moz-border-radius: 5px 0 0 5px;
      -webkit-border-radius: 5px 0 0 5px;
      border-radius: 5px 0 0 5px;
    }
    &:nth-child(4) {
      -moz-border-radius: 0 5px 5px 0;
      -webkit-border-radius: 0 5px 5px 0;
      border-radius: 0 5px 5px 0;
    }
  }
  ul {
    margin: 20px 0 0 0;
    padding: 0;
    list-style: none;
    li {
      border-top: 1px solid $grey-16;
      padding: 10px 0;
    }
  }
}

.terms {
  margin: 0 auto;
  text-align: left;
  max-width: 748px;
}

.terms__copy {
  padding-top: 80px;
  @include font-size(0.9);
}
