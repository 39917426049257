.vs-option {
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  padding: 16px;
  color: #979797;
  text-align: left;
  height: 240px;
  font-size: 14px;
  background: white;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;

  &.vs-option--small {
    height: 200px;
  }

  &.vs-option--tall {
    height: 320px;
  }

  .vs-option__images {
    width: 100%;
    height: 120px;
    margin: 0 auto 6px;
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    &--tall {
      height: 200px;
    }

    div {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 100%;
      width: 50px;
      margin-left: 10px;
      margin-right: 10px;
      flex-grow: 1;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .vs-option__glazing-image {
    width: 100%;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .vs-option__controls {
    font-size: 14px;

    input {
      margin-right: 3px;
    }
  }

  .vs-option__default-label {
    padding: 12px 2px;
  }
}

.vs-option.vs-option--finish {
  height: 200px;

  .vs-option__images {
    width: 100%;
    max-width: 250px;
    height: 80px;
    border: 1px solid #aaa;
    position: relative;
  }

  .vs-option__image--inside,
  .vs-option__image--outside {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    background-size: cover;
  }

  .vs-option__image--outside {
    clip-path: polygon(0 0, 100% 0, 0 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 0 100%);
  }

  .vs-option__image--both {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  .vs-option__image--paints {
    background: linear-gradient(
      70deg,
      #293033 20%,
      #474b4e 20.5%,
      #474b4e 40%,
      #7e7b52 40.5%,
      #7e7b52 60%,
      #b8b799 60.5%,
      #b8b799 80%,
      #fdf4e3 80.5%
    );
  }

  .vs-option__paint-code {
    position: absolute;
    right: 2px;
    bottom: 2px;
    background: rgba(255, 255, 255, 0.75);
    height: auto;
    padding: 1px 5px;
    width: auto;
    font-size: 12px;
    color: #333;
    border-radius: 2px;
  }
}

.vs-option__pricing-box {
  border: 1px solid #d8d8d8;
  margin-top: -1px;
  padding: 16px 16px 6px;
  color: #979797;
  font-size: 14px;

  &.vs-option__pricing-box--button {
    padding: 10px 16px;
  }
}

.vs-option.vs-option--150 {
  height: 150px;
}
