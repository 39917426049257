.terms-conditions-container {
  border: 1px solid #222;
  margin: 10px auto;
  padding: 10px;
  max-width: 90%;

  .terms-conditions-logo {
    width: 100%;
    max-width: 500px;
    height: auto;
    margin: 0 auto;
  }

  .terms-conditions-title-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 0 15px;

    .terms-conditions-title {
      font-size: 42px;
      font-family: gilroy-bold;
      color: #222;
      font-weight: bolder;
      text-align: left;
    }
  }

  .terms-conditions-description-wrap {
    padding: 0 5px 30px 5px;
    font-size: 15px;
    font-family: gilroy-medium;
    max-width: 400px;
    text-align: left;

    .link-terms {
      cursor: pointer;
      text-decoration: underline;
      color: #2980b9;
    }

    .key-changes-title {
      margin-bottom: 16px;
    }

    .key-changes-list {
      padding-left: 35px;
      max-width: 90%;
    }
  }

  .terms-agree-CTA {
    text-transform: uppercase;
    padding: 5px 35px;
    letter-spacing: 1px;
    font-size: 20px;
  }

  .terms-conditions-image {
    width: 100%;
    height: auto;
  }
}
.TCs-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.TCs-slice-wrap {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
}
.TCs-model-test {
  margin-bottom: 10px;
}
.TCs-summary-list {
  padding-left: 10%;
}
.TCs-model-CTA {
  text-align: center;
}
.current-version {
  background-color: #eee;
}
