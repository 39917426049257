.modal-footer {
  background-color: $white-4;
}

.modal-header {
  background-color: $white-4;
  border-color: $white-4;
  position: sticky;
  z-index: 9;
  top: 0;
  right: 0;
  left: 0;
  button {
    float: right;
  }
}

.modal-close {
  position: absolute;
  top: 8px;
  right: 10px;
}

.modal-title {
  @include font-size(1.125);
  font-family: $font-bold;
  color: $grey-1;
}

.modal-body {
  padding-bottom: 30px;
}

.modal-body .retailer_terms_and_service {
  margin-bottom: 12px;
}

.modal-options {
  text-align: center;
  margin-top: 25px;
}

#createPricingRequestModal,
#createOrderModal {
  .modal-body {
    max-height: 85vh;
    overflow-y: auto;
    padding: 0px;
  }
  .custom-content-body {
    padding: 15px;
    &.py-0 {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .modal-options {
    position: sticky;
    bottom: 0;
    background-color: #f0f1f3;
    padding: 15px;
    z-index: 9;
  }
}

#deliveryModal {
  h3,
  #deliveryModal strong {
    font-family: $font-bold;
  }
  .modal-options {
    margin-top: 5px;
  }
  .otherAddress {
    max-height: calc(100vh - 275px);
    overflow-y: auto;
  }
}

@media (min-width: 768px) {
  #payment-modal .modal-sm {
    width: 360px;
  }

  .modal-dialog.modal-lg {
    width: calc(100% - 50px);
    max-width: 1000px;
  }
  .modal-dialog.addLibraryProducts {
    width: 1000px;
  }
}

#upsellModal {
  .modal-dialog {
    width: 760px;
  }

  .modal-title {
    span {
      float: right;
      padding-right: 50px;
      font-size: 0.9rem;
      padding-top: 4px;
    }
  }

  .modal-body {
    padding: 15px;
    display: flex;
  }

  .upsell-image {
    width: 450px;
    position: relative;

    .seal {
      position: absolute;
      bottom: -14px;
      right: -26px;
    }
  }

  .upsell-text {
    width: 260px;
    padding-left: 30px;

    h1 {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 140px;
    }

    h4 {
      margin-bottom: 30px;
      font-size: 1.15rem;
    }

    .button {
      width: 100%;
      text-align: center;
      margin-bottom: 15px;
    }
  }
}

.customer__retrieve,
.page__quote-items #customerModal {
  .form-create-btn {
    text-align: center;
  }
  .modal-content {
    overflow-y: auto;
    height: 90vh;
  }
  .modal-footer {
    position: sticky;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }
}
#customerModal {
  .form-container {
    overflow-y: auto;
    max-height: 73vh;
  }
}

#finalise-modal .modal-body,
#createContractReportModal .modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.modal-options .modal-options-button {
  display: flex;
  justify-content: space-around;
  &.customized--modal {
    justify-content: space-between;
  }
}

#finalise-modal {
  .modal-body {
    padding: 0px;
  }
  .form-container {
    padding: 15px 15px 0 15px;
  }
  .form-footer-btn {
    text-align: center;
    position: sticky;
    bottom: 0;
    background-color: #f0f1f3;
    padding: 15px;
    z-index: 9;
  }
  .uploadedDocuments-btn {
    display: flex;
    float: left;
    position: fixed;
    z-index: 1;
    bottom: 0;
    padding: 15px;
    margin: 0;
  }
  .processing-container {
    padding: 15px;
  }
  .custom-modal-button {
    width: 100%;
    position: sticky;
    bottom: 0;
    padding: 15px;
    background-color: #f0f1f3;
  }
  .custom-modal-btn-group {
    display: flex;
    justify-content: end;
    align-items: center;
  }
}

#createContractReportModal {
  .modal-body {
    padding: 0;
  }
  .modal-options {
    width: 100%;
    position: sticky;
    bottom: 0;
    padding: 15px;
    background-color: #f0f1f3;
  }
}
