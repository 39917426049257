.sponsored-components {
  border: 2px solid rgb(175, 146, 83); /* Coastal's gold colour */
  border-radius: 3px;
  background: $white-2;
}

.sponsored-components__logo {
  width: 343px;
  height: 80px;
  border-radius: 3px;
  float: left;
  outline: 1px solid #d8d8d8;
  background-size: contain;
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;

  transition:
    width 0.2s ease-in-out,
    height 0.2s ease-in-out;
}

.sponsored-components__logo--collapsed {
  height: 34px;
  width: 142px;
}

.sponsored-components__disclaimer {
  display: inline-block;
  margin-right: 10px;
  line-height: 35px;
  font-size: 12px;
  font-style: italic;
}
