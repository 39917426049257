.apply-to-other-styles {
  border: 1px solid #d8d8d8;
  border-radius: 3px;

  .styles-list {
    column-width: 200px;
  }

  .apply-to-other-styles__header {
    padding: 16px;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: -1px;
  }

  .apply-to-other-styles__body {
    padding: 16px;
  }

  .apply-to-other-styles__piece-names {
    font-size: 12px;
  }

  .apply-to-other-styles__checkbox {
    margin-top: 4px;
  }

  .apply-to-other-styles__label {
    display: inline-block;
    width: calc(100% - 30px);
    vertical-align: top;
  }

  .apply-to-other-styles__label--disabled {
    color: $grey-7;
  }

  .apply-to-other-styles__note {
    margin-bottom: 16px;
    font-size: 14px;
  }
}
