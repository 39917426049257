.quote-header {
  .quote-header__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .quote-header__title {
    height: 45px;
    flex-grow: 1;
  }

  .quote-header__subtitle {
    line-height: 34px;

    br {
      display: none;
    }
  }

  .quote-header__actions {
    float: right;
    padding-top: 4px;
  }

  dt {
    margin-top: 2px;
  }
}

@media only screen and (max-width: 880px) {
  .quote-header .quote-header__subtitle {
    line-height: 23px;

    br {
      display: block;
    }
  }
}
