.glyphicons {
  display: inline-block;
  font-family: $font-family-glyphicons-regular;
  font-style: normal;
  font-weight: normal;
  line-height: 100%;
  position: relative;
  speak: none;
  top: 1px;
  vertical-align: top;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // @todo dead css review glyphicons.left
  &.left {
    padding-left: 0;
    padding-right: 8px;
  }
}

.alert {
  .glyphicons {
    @include line-height(2);
    @include font-size(1.188);
    padding: 0 5px 0 5px;
  }
}

.button--nav {
  // @todo dead css review button--nav.glyphicons - think this has been replaced with fa - fa glyph review
  .glyphicons {
    @include font-size(1.5);
    color: $white-1;
  }
}

// @todo dead css review - not found in modules
.navigation__actions {
  .glyphicons {
    @include font-size(1.125);
  }
}

.onboarding__item {
  .glyphicons {
    position: absolute;
    top: -14px;
    left: calc(50% - 14px);
    background: $white-1;
  }
}

.onboarding__item--completed {
  .glyphicons {
    @include font-size(1.5);
    top: -8px;
  }
}

.pagebar__breadcrumb {
  .glyphicons {
    @include font-size(0.625);
    @include line-height(2.125);
    padding-left: 5px;
    padding-right: 5px;
  }
}

.slice__label {
  .glyphicons {
    @include line-height(3.5);
  }
}

.slice.quote__status {
  .slice__label {
    .glyphicons {
      @include line-height(2.5);
    }
  }
}

/*------------------------------------*\
    Specific Glyphicons Styles
\*------------------------------------*/

.glyphicons-bin:before {
  content: '\E017';
}

.glyphicons-shopping-cart:before {
  content: '\E203';
}

.glyphicons-chevron-down:before {
  content: '\E602';
}

.dropdown {
  .glyphicons-chevron-down {
    padding-left: 8px;
    @include font-size(0.75);
    @include line-height(1);
  }
}

.glyphicons-chevron-left:before {
  content: '\E225';
}

.glyphicons-chevron-right:before {
  content: '\E224';
}

.glyphicons-sun:before {
  content: '\E232';
}

.glyphicons-wrench:before {
  content: '\E440';
}

.glyphicons-scissors-alt:before {
  content: '\E599';
}

.glyphicons-filter:before {
  content: '\E321';
}

.glyphicons-circle-ok:before {
  content: '\E194';
}

.glyphicons-circle-question-mark:before {
  content: '\E195';
}

.glyphicons-circle-info:before {
  content: '\E196';
}

.glyphicons-circle-exclamation-mark:before {
  content: '\E197';
}

.glyphicons-cloud-plus:before {
  content: '\E413';
}

.glyphicons-circle-arrow-right:before {
  content: '\E218';
}

.glyphicons-file-export:before {
  content: '\E360';
}

.glyphicons-clock:before {
  content: '\E055';
}

.glyphicons-map:before {
  content: '\E243';
}

.glyphicons-plus-sign:before {
  content: '\E191';
}

.glyphicons-lightbulb:before {
  content: '\E065';
}

.glyphicons-synchronization:before {
  content: '\E748';
}

.glyphicons-cloud-download:before {
  content: '\E365';
}

.glyphicons-cogwheel:before {
  content: '\E137';
}

.glyphicons-camera:before {
  content: '\E012';
}

.glyphicons-pencil:before {
  content: '\E031';
}

.glyphicons-gbp:before {
  content: '\E229';
}

.glyphicons-lock:before {
  content: '\E204';
}

.glyphicons-one-fine-empty-dot {
  margin-bottom: -0.8em;
  overflow: hidden;
  @include line-height(1);
}

.glyphicons-one-fine-empty-dot:before {
  content: '\25cb';
  @include font-size(3);
}
