.video {
  margin-top: 20px;
}

.video__hero {
  max-width: 748px;
  margin: 0 auto 80px;
  text-align: center;
  .page-subtitle {
    margin-bottom: 20px;
    font-family: $font-family-serif;
    font-style: italic;
    font-size: 1.6em;
    line-height: 1.6;
  }
  .page-title {
    margin-bottom: 0;
  }
}

.video__container {
  position: relative;
  height: 843px;
  width: 640px;
  max-width: 515px;
  display: block;
  max-height: 656px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.pdp {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  max-width: 768px;
  margin: 0 auto;
  @media screen and (min-width: $grid-breakpoint-xl) {
    max-width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  p {
    margin-bottom: 15px;
  }
}

.pdp__detail {
  order: 2;
  margin-bottom: 30px;
  @media screen and (min-width: $grid-breakpoint-xl) {
    order: 1;
    flex: 0 0 calc(100% - 738px);
    padding-right: 60px;
  }
  ul {
    margin: 0 0 0 20px;
  }
  li {
    margin-bottom: 10px;
  }
  .page-title {
    margin-bottom: 10px;
  }
  .cost {
    font-family: gilroy-extra-bold;
    font-size: 1.25rem;
    margin-bottom: 20px;
  }
  .cost__old {
    text-decoration: line-through;
    padding-right: 20px;
  }
}

.pdp__video {
  order: 1;
  @media screen and (min-width: $grid-breakpoint-xl) {
    order: 2;
    flex: 0 0 738px;
    padding-bottom: 35.5%;
  }
  iframe {
    width: 100%;
  }
}

.video__terms {
  order: 3;
  a {
    text-decoration: underline;
  }
}

@media screen and (min-width: $grid-breakpoint-xl) {
  .video {
    margin-bottom: 120px;
    .video__caption {
      padding: 8% 84px 0;
      text-align: center;
      float: left;
      width: 422px;
    }
  }

  .video__hero {
    background: $white-5;
    max-width: calc(100% - 20px);
    .video__container {
      float: right;
      width: calc(100% - 422px);
      margin-bottom: 0;
      position: relative;
      padding-bottom: 35.5%; /* 16:9 */
      height: 0;
      iframe {
        position: absolute;
        top: 0;
        left: 3px;
        width: 100%;
        height: 100%;
      }
    }
  }

  .video--detail {
    background: none;
  }
}
