.footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  clear: both;
  background: $grey-6;
  padding: 16px 40px;
  text-align: center;
  overflow: hidden;
}

.footer .credits,
.footer .credits a {
  color: $white-5;
  @include font-size(0.8);
  line-height: 1.1rem;
}

.footer .watermark {
  width: 220px;
  position: absolute;
  right: -20px;
  top: -20px;
}

.login {
  .footer {
    display: none;
  }
}

.page__compositor {
  .footer {
    display: none;
  }
}

.credits a {
  margin-bottom: 10px;
  display: inline-block;
  padding: 0 10px;
}

.footer .container {
  position: relative;
}
