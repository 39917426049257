p {
  @include header-base();
  @include line-height(1.25);
}

// @todo dead css review - can't find footbar
.footbar {
  p {
    margin: 0;
    color: $white-1;
    font-family: $font-light;
  }
}

.portal {
  // @todo dead css review - can't find footbar
  .footbar {
    p {
      color: $grey-2;
      @include font-size(0.875);
    }
  }
}
