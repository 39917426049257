.product-availability {
  margin-top: 20px;
  clear: both;
}

.product-list {
  list-style: none;
  margin-bottom: 10px;
}

.product-item {
  background-color: $white-5;
  padding: 10px;
}

.product-item:nth-child(even) {
  background-color: $white-12;
}

.product-title.inline {
  float: left;
  margin-right: 20px;
}

.product-manufacturer {
  margin: 8px 0;
  @include font-size(0.9);
}

.product-discount label {
  margin: 0 20px 0 0;
}

.glass__content p {
  margin-bottom: 5px;
}

.control {
  margin-bottom: 10px;
}

.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}
