.four-oh-four {
  /* Original file in Google Drive (TT Share > Dev > Uncompressed Site Assets)
     https://drive.google.com/drive/folders/1Acok98dH-P5IzyGz-KlHK4f7pmscbQML */
  background-image: url('/images/broken-window.jpg');

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  color: white;
  height: 480px;
  margin: 40px auto;
  width: 700px;
  overflow: hidden;
  position: relative;

  .four-oh-four__darken {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 165px;
    text-align: center;
  }

  h1 {
    font-family: $font-bold;
    font-size: 55px;
    margin-bottom: 24px;
  }

  p {
    font-size: 28px;
    margin-bottom: 36px;
    line-height: 1.4;
  }
}
