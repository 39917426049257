.discount-amount {
  margin-bottom: 20px;
}

.discount-amount label {
  margin: 0 20px 10px 0;
}

.discount-settings .slice-subtitle {
  display: inline-block;
  margin-bottom: 4px;
}
