/*

Warning: our use of FontAwesome is a little... unconventional. I've cleaned it
up a bit but at some point I think we're just going to have to rip it out and
start again with FA5 or some other icon set. I've upgraded from 4.3(?) to 4.5
but upgrading further makes all the fa-stack icons no longer vertically aligned.

There's a load of CSS overrides here, I started trying to clear it up but things
starting breaking un unpredictable ways so I've left a lot of it as-is.

  -- Martin

*/

.quote__list--controls {
  .fa-lg {
    @include font-size(1.15);
  }
}

i.fa {
  font-style: normal;
}

.fa-lg {
  @include font-size(1.33333);
  @include line-height(0.75);
  vertical-align: -15%;
}

.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  @include line-height(2);
  vertical-align: middle;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  opacity: 1;

  &.fa-stack--touch-target {
    transition-duration: 0s;
  }
}

.fa-disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

.fa-red {
  color: $red-1;
  opacity: 0.8;
}

// Funky hack: make an X out of two carets
.fa-angle-times:before {
  content: '\F105\F104';
  letter-spacing: -0.15em;
}

.slice__action--toggle {
  &.active .fa-circle {
    color: $grey-14;
  }
  .fa-circle {
    color: $grey-15;
  }
  .fa-dark {
    color: #333;
  }
}

.modal-close {
  .fa-circle {
    color: $grey-5;
  }
}

.fa-stack-close {
  .fa-angle-times {
    margin-left: -2px;
    margin-top: 4px;
    font-size: 26px;
  }
}

.fa-stack--touch-target {
  box-sizing: content-box;
  padding: 3px;
}

.pagebar__actions {
  .button {
    .fa {
      padding-left: 5px;
    }
  }
}

.visualiser {
  .card__actions {
    .fa-stack {
      @include font-size(1.2);
      margin-left: 5px;
      &.fa-stack-custom {
        display: flex;
        align-items: center;
        &.disable-delete {
          opacity: 0.2;
          cursor: not-allowed;
        }
      }
    }
  }
}

.visualiser .card__actions .fa-stack:hover {
  opacity: 0.6;
}

.slice__label .fa-stack {
  color: $white-1;
  @include line-height(3.563);
}

.slice__label.fa-dark {
  background-color: #333;
}

.slice__label .fa-circle {
  @include font-size(1.3);
}

.ng-hide,
.ng-hide .fa-spin,
.ng-hide.fa-spin {
  transition-duration: 0s;
  animation: none;
  animation-duration: 0s;
}

i.pointer {
  cursor: pointer;
}

i.disabled {
  cursor: not-allowed;
}
