.view {
  background-color: $white-1;
  height: 100%;
  &:before,
  &:after {
    display: table;
    content: ' ';
  }
  &:after {
    clear: both;
  }
}

.login {
  .view {
    background: transparent;
  }
}

.portal {
  .view {
    clear: both;
    height: auto;
    min-height: 600px;
    padding-bottom: 149px;
  }
}

.page__compositor {
  .portal {
    .view {
      padding-bottom: 0;
      min-height: 0;
    }
  }
}

// @todo dead css review - not found in modules
.view--page {
  background-color: $white-1;
}

// @todo dead css review - not found in modules
.view--draw {
  background: $white-3
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyBAMAAADsEZWCAAAAD1BMVEWkuLiovLzU1NTe3t7y8vLVTi0SAAAAKUlEQVQ4y2N0YWBg+M+ISfxlHG4yxjhk/jGOhsFoGIyGwUgLA0EcMgwAsAUFhOHfQMEAAAAASUVORK5CYII=)
    repeat;
}

// @todo dead css review - not found in modules
.view--configure {
  background: $blue-2
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyBAMAAADsEZWCAAAAD1BMVEWkuLiovLzU1NTe3t7y8vLVTi0SAAAAKUlEQVQ4y2N0YWBg+M+ISfxlHG4yxjhk/jGOhsFoGIyGwUgLA0EcMgwAsAUFhOHfQMEAAAAASUVORK5CYII=)
    repeat;
}

.view__spacer {
  border-top: 1px solid $white-6;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.view--hidden {
  display: none;
}
