.termsAndConditions {
  & .hide-onboarding {
    display: none;
  }
}

.onboarding {
  position: relative;
  max-width: 1160px;
  margin: 20px auto 30px;
  text-transform: uppercase;
  @include font-size(0.8125);
  letter-spacing: 1px;
}

.onboarding__progress {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 200px);
  margin: 0 100px;
  height: 2px;
  background-color: $white-9;
}

.onboarding__list {
  display: flex;
  align-content: center;
}

.onboarding__item {
  flex: 1;
  padding: 0;
  margin: 0;
  position: relative;
  padding-top: 20px;
  list-style: none;
  text-align: center;
}

.onboarding__link {
  color: $grey-5;
  transition: 0.3s all ease-in-out;
  &:hover {
    color: $grey-12;
  }
}

.onboarding__item--completed {
  .onboarding__link {
    color: $grey-12;
  }
}

.onboarding__message {
  padding: 10px 0;
  background: rgba(244, 179, 80, 0.4);
  text-align: center;
}
