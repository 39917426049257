// @todo dead css review - can't find footbar
.footbar {
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.footbar strong {
  font-family: $font-bold;
}

.footbar__container {
  max-width: 960px;
  margin: 0 auto;
}

.footbar__container:before,
.footbar__container:after {
  display: table;
  content: ' ';
}

.footbar__container:after {
  clear: both;
}

.footbar__left,
.footbar__right {
  float: left;
  min-height: 1px;
  padding-top: 20px;
  position: relative;
}

.footbar__left {
  padding-left: 20px;
  width: 50%;
}

.footbar__right {
  padding-right: 20px;
  text-align: right;
  width: 50%;
}

.portal {
  .footbar__container {
    max-width: 1200px;
  }

  .footbar {
    padding-bottom: 20px;
    position: relative;
    bottom: auto;
  }
}
