.control__unit {
  position: relative;
  padding: 0;

  span {
    @include font-size(1);
    color: $grey-3;
    display: block;
    font-family: $font-light;
    line-height: 34px;
    padding-left: 12px;
    padding-right: 12px;
    position: absolute;
    text-align: right;
    top: 0;
    width: 100%;
    z-index: 2;
    pointer-events: none;
  }

  &.control__unit--left-handed {
    span {
      text-align: left;
    }
    input {
      padding-left: 30px;
      padding-right: 15px;
    }
  }

  &.control__unit--right-handed {
    input {
      padding-left: 15px;
      padding-right: 35px;
    }
  }

  input {
    padding-right: 28px;
    position: relative;
    z-index: 1;
    text-align: right;
  }

  &.control__unit--no-label input {
    padding-left: 15px;
    padding-right: 15px;
  }

  &.control__unit--small {
    display: inline-block;

    &.control__unit--auto-width {
      width: auto;
    }

    span {
      padding-right: 6px;
      padding-left: 6px;
      line-height: 28px;
      height: 28px;
      z-index: 2;
    }

    input {
      padding-left: 6px;
      padding-right: 20px;
      height: 27px;
      width: 76px;
    }

    &.control__unit--left-handed input {
      padding-left: 20px;
      padding-right: 6px;
    }

    &.control__unit--no-label input {
      padding-left: 6px;
      padding-right: 6px;
    }
  }
}

label.multi {
  margin: 0 5px 0 15px;
}

.disabled {
  cursor: not-allowed;
}
