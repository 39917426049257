table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-bottom: $white-9 solid 2px;
  margin-bottom: 30px;
  th,
  td {
    padding: 6px 10px;
    vertical-align: middle;
    width: 25%;
  }
  th {
    font-family: $font-bold;
    text-transform: uppercase;
    background: $white-3;
    border-bottom: $white-9 solid 2px;
    padding: 16px 10px;
    text-align: center;
  }
  tbody {
    tr {
      min-height: 53px;
      transition: all 0.2s ease-in;
      th {
        background: transparent;
        border: none;
      }
      td:nth-child(1) {
        font-family: $font-bold;
        text-transform: capitalize;
      }
      &:nth-child(even) {
        background: $white-8;
      }
      &:hover {
        background: $white-9;
      }
    }
    td {
      min-height: 53px;
      &:first-child {
        text-align: left;
      }
    }
  }

  &.borderless-table {
    border-bottom: none;
    margin-bottom: 10px;
    width: auto;

    tbody {
      tr {
        background: none;

        td {
          padding: 0 12px 4px 0;
          width: auto;

          small {
            font-family: $font-light;
          }
        }
      }
    }

    * {
      transition-duration: 0s;
    }

    &--compact {
      td {
        padding-right: 0;
      }
    }
  }
}

.is-invalid {
  border-color: #c0392b !important;
}
.max-users-icon {
  cursor: pointer;
  margin-left: 10px;
}
.disabled-icon {
  opacity: 0.5;
  cursor: not-allowed;
}
