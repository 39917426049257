.navigation {
  height: 70px;
  max-width: 1200px;
  background: $white-1;
  margin: 0 auto;
}

// @todo dead css review - not found in modules
.navigation__actions {
  float: right;
  min-height: 1px;
  padding-right: 20px;
  position: relative;
  text-align: right;
}

.navigation__container {
  height: 65px;
  max-width: 960px;
  margin: 0 auto;
  &:before,
  &:after {
    display: table;
    content: ' ';
  }
  &:after {
    clear: both;
  }
}

.navigation__nav {
  float: right;
  height: 30px;
  @include line-height(1.875);
  margin: 21px 0 0;
  min-height: 1px;
  position: relative;
  padding: 0 0 0 20px;
  .button--nav .fa {
    @include font-size(1.375);
    @include line-height(1.625);
    color: $grey-5;
  }
  .dropdown {
    float: left;
    margin: 0;
    height: 30px;
    @include line-height(1.875);
    .text {
      text-transform: uppercase;
      display: inline-block;
      padding: 0 10px;
      margin: 0;
      @include font-size(0.875);
      letter-spacing: 0.1em;
      color: $black-1;
      float: left;
      &:hover {
        background: #eee;
      }
    }
    &.open {
      .button--nav .fa {
        color: $white-8;
      }
      .text {
        background: $white-11;
      }
    }
  }
  .dropdown--user {
    margin: -4px 10px 0 10px;
  }
  .navigation__item {
    border-right: 1px solid $white-9;
    display: inline-block;
    color: $black-1;
    float: left;
    @include font-size(0.875);
    letter-spacing: 0.1em;
    @include line-height(1.875);
    margin: 0;
    padding: 0 10px;
    text-transform: uppercase;
    transition: 200ms ease-in-out;
  }
  .navigation__item:hover {
    text-decoration: none;
    background: $white-8;
  }
  .navigation__item--active {
    text-decoration: underline;
    font-family: $font-bold;
  }
}

.navigation__nav--right {
  float: right;
  padding-right: 20px;
}

.portal {
  .navigation__container {
    max-width: 1200px;
  }
  .pagebar__nav {
    .navigation__item {
      transition: color 200ms ease-in-out;
      display: inline-block;
      color: $grey-7;
      @include font-size(1.063);
      padding: 4px 1px auto;
      @include line-height(3.563);
      margin: auto 8px;
      &:hover {
        color: $grey-1;
      }
    }
    .navigation__item--active {
      color: $grey-1;
      border-bottom: 3px solid $grey-1;
    }
  }
}
