.how2 {
  padding-bottom: 0;
  & h1 {
    padding-top: 5px;
  }
  &__intro {
    margin-bottom: 30px;
    p {
      margin-bottom: 10px;
      a {
        font-family: $font-bold;
        color: #333;
        border-bottom: 3px solid $white-9;
        margin-right: 10px;
        &:hover {
          text-decoration: none;
          transition: all 0.3s ease-in;
          border-bottom: 3px solid $grey-6;
        }
      }
    }
  }
  &__section {
    margin-bottom: 30px;
  }

  &__caption {
    p {
      margin-bottom: 10px;
    }
    ul {
      padding-left: 20px;
      margin: 0 0 10px 20px;
    }
  }
}

.page__video {
  .portal .view {
    padding-bottom: 0px;
    height: auto;
    max-height: 620px;
    .video__content {
      position: relative;
      overflow-y: scroll;
      top: 10px;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        width: 8px;
        background-color: #f5f5f5;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
      & .video__content--container {
        padding: 0px 20px;
        height: 58vh;
      }
    }
  }
}

@media (max-width: 992px) {
  .how2__caption {
    display: block;
  }
}

@media (min-width: 1900px) {
  .page__video .portal .view .video__content .video__content--container {
    height: 75vh;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .page__video .portal .view .video__content .video__content--container {
    height: 60vh;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .page__video .portal .view .video__content .video__content--container {
    height: 63vh;
  }
}
