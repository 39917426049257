.page-numbers {
  text-align: center;
  margin-top: 18px;

  a {
    font-family: $font-bold;
  }
}

.page-numbers__number {
  padding: 3px 8px;
  margin-left: 2px;
  cursor: pointer;
  color: $black-1;

  &.page-numbers__number--selected {
    pointer-events: none;
    cursor: default;
    text-decoration: underline;
  }
}

.page-numbers__disabled-button,
.custom-page .page-numbers-custom .page-numbers__disabled-button {
  pointer-events: none;
  cursor: default;
  color: $grey-10;
}

.page-numbers-custom {
  border: 1px solid gray;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0 25px;
  border-radius: 35px;
  width: fit-content;
}
.custom-page {
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-numbers-custom a {
  font-family: gilroy-bold;
  display: block;
  letter-spacing: 0.1em;
}
.custom-page .page-numbers-custom a i.fa {
  font-style: unset;
  color: $black-1;
  background: white;
}

.custom-page .page-numbers-custom a.page-numbers__number.page-numbers__number--selected {
  pointer-events: none;
  cursor: default;
  background: grey;
  text-decoration: none;
  color: white;
  border-radius: 5px;
}

.custom-page .page-numbers-custom a i.fa.page-numbers__disabled-button {
  color: gray;
}
