/*------------------------------------*\
    .button
\*------------------------------------*/

.button {
  border: 1px solid transparent;
  border-radius: 17px;
  cursor: pointer;
  display: inline-block;
  font-family: $font-light;
  white-space: nowrap;
  @include font-size(1);
  height: 34px;
  outline: none;
  overflow: hidden;
  padding: 6px 12px;
  text-decoration: none;
  vertical-align: top;
  -webkit-tap-highlight-color: transparent;
  transition:
    border-color 300ms ease-in-out,
    color 300ms ease-in-out;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.dropdown {
  .button {
    min-width: 65px;
    &.button--nav {
      min-width: initial;
    }
  }
}

.footer {
  .button {
    margin-bottom: 10px;
    text-transform: uppercase;
    @include font-size(0.9);
    letter-spacing: 1px;
    font-weight: normal;
  }
}

// @todo dead css review - not found in modules
.navigation__actions {
  .button {
    margin-left: 8px;
  }
}

.modal-options,
.modal-footer {
  .button {
    margin: 0 5px;
  }
}

.pagebar__actions {
  .button {
    margin-left: 8px;
  }
}

.page-title__actions {
  .button {
    float: right;
  }
}

.portal {
  .view {
    .button {
      margin-left: 5px;
      margin-right: 5px;
      &:first-child {
        margin-left: 0;
        &.button--left-margin-anyway {
          margin-left: 5px;
        }
      }
      &:last-child {
        margin-right: 0;
        &.button--right-margin-anyway {
          margin-right: 5px;
        }
      }
      &.button--card {
        height: 52px;
        margin: 1px 0;
        @include line-height(2.188);
      }
    }
    .pdp {
      .button {
        margin: 20px auto;
        width: 200px;
        text-align: center;
      }
    }
  }
}

.pricing-table {
  .button {
    margin: 20px auto 0;
  }
}

.slice__action {
  .button--order {
    .button:first-child {
      margin-left: 12px;
    }
  }
}

.toolbar__left {
  .button {
    margin-right: 8px;
  }
}

.toolbar__right {
  .button {
    margin-left: 8px;
  }
}

/*------------------------------------*\
    .button--primary
\*------------------------------------*/

.button--primary {
  background-color: $green-1;
  border-color: $green-1;
  color: $white-1;
  &:hover {
    background-color: $green-2;
    border-color: $green-2;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

/*------------------------------------*\
    .button--secondary
\*------------------------------------*/

.button--secondary {
  background-color: $white-1;
  border-color: $white-1;
  color: $grey-1;
  &:active,
  &:focus,
  &:hover {
    border-color: $grey-1;
  }
  &:disabled,
  &.button--disabled {
    color: $white-7;
  }
  &:disabled:active,
  &:disabled:focus,
  &:disabled:hover,
  &.button--disabled:active,
  &.button--disabled:focus,
  &.button--disabled:hover {
    border-color: $white-1;
  }
}

/*------------------------------------*\
    .button--tertiary
\*------------------------------------*/

.button--tertiary {
  background: $white-8;
  border-color: $white-8;
  color: $grey-1;
  &:active,
  &:hover,
  &.button--active {
    background: $white-7;
    color: $grey-1;
  }
  &:disabled,
  &.button--disabled,
  &.button--disabled:active,
  &.button--disabled:focus,
  &.button--disabled:hover {
    color: $grey-3;
    background: $white-11;
    cursor: default;
  }
}

/*------------------------------------*\
    .button--nav
\*------------------------------------*/

.button--nav {
  padding: 4px 8px 5px;
  background-color: $blue-3;
  transition: background-color 250ms ease-in-out;
}

.navigation__nav {
  .button--nav {
    border-radius: 3px;
    border-color: $grey-4;
    background: $white-8;
  }
  .dropdown.open {
    .button--nav {
      border-color: $grey-4;
      background-color: $grey-5;
    }
  }
}

/*------------------------------------*\
    .button--navbar @todo dead css review - not found in modules
\*------------------------------------*/

.button--navbar {
  transition: background-color 250ms ease-in-out;
  border-color: $white-4;
  color: $white-1;
  &:active,
  &:focus,
  &:hover {
    background-color: $white-1;
    color: $blue-3;
  }
  &.button--active {
    color: $blue-3;
    background-color: $white-1;
  }
}

/*------------------------------------*\
    .button--upload
\*------------------------------------*/

.button--upload {
  background-color: $green-1;
  border-color: $green-1;
  color: $white-1;
  &:active,
  &:focus,
  &:hover {
    border-color: $green-3;
  }
}

/*------------------------------------*\
    .button--cancel
\*------------------------------------*/

.button--cancel {
  border-color: $grey-6;
}

.button--danger {
  background-color: $red-2;
  border-color: $red-2;
  color: $white-1;
  &:hover {
    background-color: $red-1;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

/*------------------------------------*\
    .button__visualiser, button__visualiser-green, button__visualiser-small
    @todo update template naming convention to match other buttons.
    @todo sort out use of spans - clean styles below
\*------------------------------------*/

.button__visualiser {
  span {
    @include font-size(2.75);
  }
}

.button__visualiser-green {
  color: $green-1;
}

.button__visualiser-small {
  span {
    @include font-size(2.375);
    margin-top: -1px;
  }
}

/*------------------------------------*\
    .button--support
    Freshchat Widget
\*------------------------------------*/
.button--support {
  background-color: $blue-4;
  border-color: $blue-4;
  color: $white-1;
  &:hover {
    background-color: $blue-5;
    border-color: $blue-5;
  }
}

/*------------------------------------*\
    .btn-info from bootstrap @todo dead css review - not found in modules
\*------------------------------------*/
.btn-info {
  color: $white-1;
  background-color: $blue-11;
  border-color: $blue-12;
}

.btn-info:focus,
.btn-info.focus {
  color: $white-1;
  background-color: $blue-7;
  border-color: $blue-9;
}

.btn-info:hover {
  color: $white-1;
  background-color: $blue-7;
  border-color: $blue-8;
}

.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled.focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info.focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  background-color: $blue-11;
  border-color: $blue-12;
}

/*------------------------------------*\
    .close
\*------------------------------------*/
.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  float: right;
  @include font-size(1.125);
  font-weight: normal;
  @include line-height(1);
  color: $black-1;
  text-shadow: 0 1px 0 $white-1;
  filter: alpha(opacity=20);
  opacity: 0.2;
  margin-left: 10px;
}

/*------------------------------------*\
    .in-line-with-button - For test that should be in line with a button
\*------------------------------------*/

.in-line-with-button {
  line-height: 34px;
}

/*------------------------------------*\
    pricing button - color of the button according to status of quotes
\*------------------------------------*/

.button--finalising,
.button--finalising:hover,
.button--finalised,
.button--finalised:hover,
.button--sent,
.button--sent:hover,
.button--viewed,
.button--viewed:hover {
  color: #fff;
  background-color: $orange-1;
}

.button--confirmed,
.button--confirmed:hover {
  color: #fff;
  background-color: $blue-10;
}
.button--expired,
.button--expired:hover {
  color: #fff;
  background-color: $red-1;
}
