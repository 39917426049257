select {
  @include font-size(1);
  background-color: transparent;
  border-radius: 5px; /* to match Chrome's default select styling */
  border: 1px solid $grey-13;
  box-shadow: none;
  color: $grey-11;
  font-family: $font-light;
  font-weight: normal;
  height: 34px;
  margin: 0;
  outline: none;
  padding: 6px 8px;

  &.select--block {
    width: 100%;
  }

  &.active,
  &:active,
  &.focus {
    border: 1px solid $black-1;
  }
}
