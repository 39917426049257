.navigation__logo {
  background: $white-1;
  float: left;
  height: auto;
  max-height: 58px;
  min-height: 1px;
  max-width: 160px;
  position: relative;
  top: 50%;
  width: auto;
  -webkit-transform: translateY(-50%) translateX(20px);
  -moz-transform: translateY(-50%) translateX(20px);
  -ms-transform: translateY(-50%) translateX(20px);
  -o-transform: translateY(-50%) translateX(20px);
  transform: translateY(-50%) translateX(20px);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media only screen and (min-width: $grid-breakpoint-lg) {
  .navigation__logo {
    max-width: 300px;
  }
}
