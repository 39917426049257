.toolbar {
  height: 60px;
  .control__text input:focus,
  .control__text input:active {
    background-color: $white-1;
  }
  .toolbar__container {
    max-width: 960px;
    margin: 0 auto;
    &:before,
    &:after {
      display: table;
      content: ' ';
    }
    &:after {
      clear: both;
    }
    .toolbar__left,
    .toolbar__right {
      float: left;
      min-height: 1px;
      padding-top: 20px;
      position: relative;
    }
    .toolbar__left {
      padding-left: 20px;
      width: 60%;
    }
    .toolbar__right {
      padding-right: 20px;
      text-align: right;
      width: 40%;
    }
  }
}

.portal {
  .toolbar {
    .toolbar__container {
      max-width: 1200px;
    }
  }
}
