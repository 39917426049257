h2 {
  @include header-base();
  &.group__heading {
    border-bottom: 1px solid $white-6;
    margin: 20px -5px;
    @include font-size(1);
    padding: 20px 5px 5px 5px;
  }
}

h2.heading-2 {
  font-family: gilroy-extra-bold;
  font-size: 20px;
  margin: 12px 0 18px;
}

.card__heading {
  h2 {
    display: inline-block;
    padding: 5px 2px;
    margin-right: 18px;
    border-bottom: 3px solid $grey-1;
    @include font-size(1.063);
    color: $grey-1;
  }
}

.segmentation__control {
  h2 {
    @include font-size(1.125);
  }
}

.customer__list {
  .segmentation__control {
    h2 {
      @include font-size(0.9375);
    }
  }
}

.subtitle {
  font-family: $font-bold;
  @include font-size(1.1);
  margin-top: 10px;
  margin-bottom: 15px;

  .help & {
    margin-top: 0;
    margin-bottom: 10px;
  }
}
