.component-options__header {
  margin-top: 20px;

  .heading-3 {
    margin: 0;
    line-height: 35px;
  }
  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
