.compositor {
  position: relative;
  overflow: hidden;
  display: block;
}

.compositor__bank-image {
  max-width: 100%;
  max-height: 100%;
}

.compositor__controls {
  border-bottom: 4px solid #eee;
  margin-bottom: 20px;
}

.compositor__container {
  overflow: hidden;
}

.compositor__items {
  overflow-y: scroll;
  border-radius: 5px;
  background: #eee;
  padding: 20px 25px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
