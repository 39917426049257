.retailer-product-options {
  @media (min-width: 768px) {
    .borderless-table {
      width: 100%;
    }
  }

  small {
    font-size: 12px;
  }
}
