.tabs {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 0;
  &.tabs-custom {
    text-transform: none;
    letter-spacing: unset;
  }
  li {
    margin: 0;
    padding: 0;
    display: inline-block;

    &:last-child .tabs__item {
      margin-right: 0;
    }
    /* Grey border at the bottom of the tabs, repeated every 38px in case it wraps */
    background-image: repeating-linear-gradient(
      180deg,
      transparent,
      transparent 34px,
      #dbd8d3 34px,
      #dbd8d3 38px
    );
    &:hover {
      background: #eee;
      background-image: repeating-linear-gradient(
        180deg,
        transparent,
        transparent 34px,
        #dbd8d3 34px,
        #dbd8d3 38px
      );
    }
  }
}

.tabs__item {
  display: inline-block;
  position: relative;
  height: 38px;
  width: auto;
  @include font-size(0.8125);
  @include line-height(2.375);
  padding: 0 10px;
  margin-right: 0;
  border-bottom: 4px solid transparent;
  cursor: pointer;
  &.tabs__material {
    min-width: 85px;
    text-align: center;
    margin-right: 0;
    padding: 0 15px;
  }

  &.tabs__item--active {
    border-color: $grey-6;
  }
}

.tab--archive a {
  font-family: 'gilroy-medium';
  color: #999;
}

.tab--archive .tabs__item--active {
  color: $grey-6;
  border-color: $grey-6;
}
