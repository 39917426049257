.glass {
  width: 100%;
  margin: 30px 0 0;
}

.glass__list {
  width: 150px;
  float: left;
  list-style: none;
  @include font-size(0.8125);
  letter-spacing: 1px;
  text-transform: uppercase;
}

.glass__item {
  width: 100%;
  height: 30px;
  @include line-height(1.875);
  border-right: 3px solid $white-9;
  cursor: pointer;
}

.glass__item:hover {
  color: $black-1;
}

.glass__item.active,
.glass__item.active:hover {
  color: $black-1;
  border-color: $grey-6;
}

.glass__content {
  width: calc(100% - 150px);
  float: left;
  padding-left: 20px;
}

.glass__conditional {
  float: left;
  width: 280px;
}

.glass__cta {
  clear: both;
  float: right;
}

.glass__markup label {
  margin: 0 20px 10px 0;
}
