/*------------------------------------*\
    Fonts
\*------------------------------------*/
@font-face {
  font-family: font-awesome;
  src: url('../fonts/fontawesome-webfont.eot?v=4.3.0');
  src:
    url('../fonts/fontawesome-webfont.eot?#iefix&v=4.3.0') format('embedded-opentype'),
    url('../fonts/fontawesome-webfont.woff2?v=4.3.0') format('woff2'),
    url('../fonts/fontawesome-webfont.woff?v=4.3.0') format('woff'),
    url('../fonts/fontawesome-webfont.ttf?v=4.3.0') format('truetype'),
    url('../fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: gilroy-extra-bold;
  src: url('../fonts/Gilroy-Extra-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: gilroy-bold;
  src: url('../fonts/Gilroy-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: gilroy-medium;
  src: url('../fonts/Gilroy-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: gilroy-light;
  src: url('../fonts/Gilroy-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: glyphicons-regular;
  src: url('../fonts/glyphicons-regular.eot');
  src:
    url('../fonts/glyphicons-regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/glyphicons-regular.woff2') format('woff2'),
    url('../fonts/glyphicons-regular.woff') format('woff'),
    url('../fonts/glyphicons-regular.ttf') format('truetype'),
    url('../fonts/glyphicons-regular.svg#glyphiconsregular') format('svg');
}

/*------------------------------------*\
    Font Variables
\*------------------------------------*/

$font-family-font-awesome: font-awesome;

$font-extra-bold: gilroy-extra-bold;
$font-bold: gilroy-bold;
$font-medium: gilroy-medium;
$font-light: gilroy-light;

$font-family-glyphicons-regular: glyphicons-regular;
$font-family-serif: 'Georgia', times, serif;

/*------------------------------------*\
    Font Size Guide

    8px = 0.5rem
    9px = 0.5625rem
    10px = 0.625rem
    11px = 0.6875rem
    12px = 0.75rem
    13px = 0.8125rem
    14px = 0.875rem
    15px = 0.9375rem
    16px = 1rem (base)
    18px = 1.125rem
    19px = 1.188rem
    20px = 1.25rem
    22px = 1.375rem
    24px = 1.5rem
    26px = 1.625rem
    28px = 1.75rem
    30px = 1.875rem
    32px = 2rem
    34px = 2.125rem
    35px = 2.188rem
    36px = 2.25rem
    38px = 2.375rem
    40px = 2.5rem
    44px = 2.75rem
    56px = 3.5rem
\*------------------------------------*/

$base-font-multiplier: 1; // master font size integer

/*------------------------------------*\
    Font & Line Height Mixins
\*------------------------------------*/

/*
Rem Unit font sizes with relative fallback http:/seesparkbox.com/foundry/scss_rem_mixin_now_with_a_better_fallback
Usage: @include font-size(1, large);
*/
@mixin font-size($decimal-size) {
  font-size: $decimal-size * $base-font-multiplier * 16px; // Fallback for older browsers
  font-size: $decimal-size * 1rem;
}

/* Usage: @include line-height(1); */
@mixin line-height($decimal-size) {
  line-height: $decimal-size * $base-font-multiplier * 16px; // Fallback for older browsers
  line-height: $decimal-size * 1rem;
}

@mixin header-base() {
  border: 0;
  font-weight: normal;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}
