.quote__items {
  border: none;
  border-spacing: 0 10px;
  border-collapse: separate;
  width: 100%;
  margin-bottom: 8px;

  &.order_part {
    margin-top: 10px;
    margin-bottom: -8px;
  }

  tr.quote__item-container {
    height: 60px;
    border: none;
    background: white;
  }

  thead {
    tr {
      height: auto;
    }
    td {
      border: none;
      font-family: $font-bold;
      vertical-align: bottom;
      padding-right: 6px;
    }
  }

  td {
    border-top: 1px solid $white-6;
    border-bottom: 1px solid $white-6;
    width: auto;
    &.quote__items--actions {
      text-align: right;
      padding-top: 8px;
      padding-right: 6px;
      &.quote__items--custom--actions {
        width: 15%;
        @media (min-width: 992px) and (max-width: 1199.98px) {
          width: 18%;
        }
        @media (min-width: 768px) and (max-width: 991.98px) {
          width: 23%;
        }
        @media (max-width: 767.98px) {
          width: 23%;
        }
      }
    }
  }

  .quote__items--financial-no-border,
  .quote__items .quote__items--financial-label {
    border: none;
  }

  .quote__items--item {
    min-width: 50%;
    padding: 6px 10px;
  }

  .quote__items--action-label {
    display: inline-block;
    margin-right: 8px;
    font-size: 12px;
    font-style: italic;
  }

  .quote__items--cannot-finalise {
    @include font-size(0.75);

    h4 {
      font-size: 14px;
      font-family: gilroy-medium;
      color: $red-1;
    }
  }

  .quote__items--cost,
  .quote__items--value {
    text-align: right;
    padding-right: 0;

    .fa {
      margin-right: 8px;
    }
  }

  .quote__items--cost-label {
    line-height: 27px;
    margin-right: 4px;
    display: inline-block;
  }

  .quote__items--value-span {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .quote__items--image {
    width: 40px;
    padding-right: 0;

    .slice__image {
      margin: 0;
    }
  }

  td:last-child {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    border-right: 1px solid $white-6;
  }

  td:first-child {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    border-left: 1px solid $white-6;
  }

  .quote__item-container--invalid {
    td,
    td:last-child,
    td:first-child {
      border-color: red;
    }
  }
}

.quote__items--show-financials {
  .button {
    width: 160px;
    margin: 0;

    &.ng-hide {
      transition-duration: 0s;
    }
  }

  .selling-price-checkbox {
    display: inline-block;
    margin-top: 8px;
    margin-right: 8px;
  }
}

.quote__description {
  @include font-size(1);
  height: 29px;

  .grid__col--6 {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.quote__items--financial {
  text-align: right;
  padding-right: 0;
  width: 10%;
}

.quote__items--financial-row {
  background: #eee;
  line-height: 28px;
  transition: none;

  td {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

table tbody .quote__items--financial-row {
  td:first-child {
    text-transform: none;
  }

  .control__text--label-field {
    margin-left: -3px;
  }
}

.quote__items--financial-label {
  text-align: right;
  padding-right: 10px;
}

.quote__items--subtitle {
  font-family: $font-light;
  text-transform: none;
  @include font-size(0.75);
  font-style: italic;
}

.quote__items--financial-total {
  font-family: $font-bold;
}

.page__quote-items {
  .quote__items .quote__items--right-cell {
    position: relative;
  }
  .go-up {
    margin-top: -28px;
  }
  .customers__info {
    text-align: left;
  }
}

.costing-table .quote__items--financial-cost {
  color: $grey-13;
}

.quote__items--financial-note {
  font-size: 10px;
  text-align: right;
  font-style: italic;
}

// @todo dead css review - not found in modules
.quotes-summary {
  padding-top: 13px;
}

// @todo dead css review - not found in modules
.quotes-summary .total-cost {
  display: block;
  @include line-height(1);
}

.tt-quote-actions {
  display: inline-block;
  background: $white-1;
}

.quote-loader {
  @include font-size(2.8);
  opacity: 0.4;
}

.quote__items--financial-options {
  font-family: $font-light;
}

.quote__items .quote__items--heading-row {
  background: none;
  &:hover {
    background: none;
  }
  td {
    border: none;
    border-left: none;
    border-right: none;
    padding: 0;
    vertical-align: bottom;
    text-align: right;
    font-family: $font-light;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: left;
    }
  }
}

.quote__items--custom-link {
  cursor: pointer;
  color: #4a4a4a;
  font-weight: 900;
}
.custom--tittle {
  font-size: 16px;
  color: #4b4b4b;
  margin: 0 0 10px 0;
}

.hidden-column {
  visibility: hidden;
}
.show-column {
  visibility: visible;
}

.quote__items--item {
  &.p-right {
    padding-right: 0;
  }
}
.quote__items--custom {
  padding-left: 0;
  height: 53px;
}

.quote__items--cost .full-link {
  display: block;
  height: 100%;
}
