.control__text {
  label {
    font-family: $font-light;
    margin-bottom: 6px;
    display: inline-block;

    &.portal__label {
      display: block;
    }

    &.portal__column {
      width: 150px;

      &--textbox {
        width: 153px;
      }
    }
  }

  /* Most 'normal' inputs */
  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='number'],
  input[type='search'],
  input[type='tel'],
  input[type='url'],
  textarea {
    width: 100%;
    margin-bottom: 10px;

    .grid__col--12 & {
      max-width: 700px;
    }
  }

  select {
    margin-bottom: 10px;
  }

  p {
    padding: 8px 0;
  }

  .dropdown {
    display: block;
    padding-bottom: 0;
  }

  .onoffswitch--off {
    color: #aaa;
  }

  .onoffswitch--disabled {
    color: #ddd;
    cursor: not-allowed;
  }

  .onoffswitch {
    position: relative;
    width: 76px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  .onoffswitch-checkbox {
    display: none;
  }

  .onoffswitch__holder {
    display: flex;
    align-items: center;
  }

  .onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    height: 29px;
    padding: 0;
    line-height: 29px;
    border: 0px solid #999999;
    border-radius: 37px;
    background-color: #cccccc;
    margin-bottom: 0;
  }

  .onoffswitch-label:before {
    content: '';
    display: block;
    width: 37px;
    margin: -4px;
    background: #555;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 43px;
    border-radius: 37px;
    box-shadow: 0 6px 12px 0px #757575;
  }

  .onoffswitch-checkbox:checked + .onoffswitch-label {
    background-color: #29e67b;
  }

  .onoffswitch-checkbox:checked + .onoffswitch-label,
  .onoffswitch-checkbox:checked + .onoffswitch-label:before {
    border-color: #29e67b;
  }

  .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
  }

  .onoffswitch-checkbox:checked + .onoffswitch-label:before {
    right: 0px;
    background-color: #27ae60;
    box-shadow: 3px 6px 18px 0px rgba(0, 0, 0, 0.2);
  }

  .onoffswitch-checkbox[disabled] + .onoffswitch-label {
    background-color: #eee;
    cursor: not-allowed;
  }

  .onoffswitch-checkbox[disabled] + .onoffswitch-label .onoffswitch-inner {
    cursor: not-allowed;
  }

  .onoffswitch-checkbox[disabled] + .onoffswitch-label:before {
    background-color: #eee;
    box-shadow: 3px 6px 18px 0px rgba(0, 0, 0, 0.2);
    cursor: not-allowed;
  }

  .onoffswitch--small {
    width: 45px;
    margin-bottom: 4px;
    margin-top: 4px;
  }

  .onoffswitch--small .onoffswitch-label {
    height: 16px;
    line-height: 22px;
    border-radius: 15px;
  }

  .onoffswitch--small .onoffswitch-label:before {
    width: 22px;
    margin: -3px;
    border-radius: 22px;
    right: 26px;
  }

  .onoffswitch--tiny {
    margin-top: 2px;
    width: 16px;
  }

  .onoffswitch--tiny .onoffswitch-label {
    height: 16px;
    line-height: 22px;
    border-radius: 15px;
  }

  .onoffswitch--tiny .onoffswitch-label:before {
    width: 22px;
    margin: -3px;
    border-radius: 28px;
    right: 20px;
  }

  textarea::placeholder {
    color: gray;
  }
}

/*
  Should be <div class=".control__text"><label>Label</label><input></div> but
  is mostly written with the div and the label the other way round, or sometimes
  with a strong instead of the div, so this bit of CSS makes the incorrect style
  match the correct style without having to change loads of lines.
*/
label.control__text > div:first-child {
  font-family: $font-light;
  margin-bottom: 6px;
}

label.control__text > strong:first-child {
  display: block;
  margin-bottom: 6px;
}

.control__text--readonly {
  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='number'],
  input[type='search'],
  input[type='tel'],
  input[type='url'],
  textarea {
    background-color: $white-4;

    &.active,
    &:active,
    &:focus {
      background-color: $white-4;
      border: 1px solid $white-7;
    }
  }
}

.control__text--warning {
  input,
  textarea,
  #stripe__card--element {
    border: 1px solid $red-2;
  }

  input:focus,
  input:active,
  textarea:focus,
  textarea:active {
    border: 1px solid $red-2;
  }
}

.control__text--right {
  input {
    text-align: right;
  }
}

.control__text--warning {
  .control__message {
    display: block;

    em {
      display: block;
    }
  }
}

.error_message {
  color: #c0392b;
  margin: 0 0 5px 0;
}

.character__count {
  margin-bottom: 10px;
  padding: 0 10px;
}

.termsAndConditions {
  input[type='date'],
  input[type='file'] {
    margin-bottom: 10px;
    text-align: center;
  }
}

.control__termsMessage {
  padding: 20px;
  text-align: center;
  p {
    color: #aaa;
    margin-bottom: 5px;
  }
}
