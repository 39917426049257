.login__panel {
  width: 360px;
  margin: 70px auto 0;
  padding: 20px 0 0 0;
  background: #fff;
  text-align: center;
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.login__branding {
  text-align: center;
  color: $grey-6;
  display: block;
  @include font-size(1);
  font-family: $font-bold;
  @include line-height(4);
  padding: 0 10px;
}

.login__branding--logo {
  height: 64px;
  max-width: 100%;
}

.login__form {
  padding: 20px 20px 20px 20px;
  text-align: left;
}

.login__alert {
  @include line-height(1.25);
  text-align: center;
}

.login__forgotten {
  @include font-size(0.75);
  color: $grey-8;
  padding-top: 10px;
  float: right;
}

.login__signin {
  width: 100%;
  margin: 30px 0 !important;
}

.login .ttlogo {
  text-align: center;
  width: 200px;
  margin: 0 auto 0 -100px;
  position: absolute;
  bottom: 40px;
  left: 50%;
}
