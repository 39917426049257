textarea {
  @include font-size(1);
  background-color: white;
  border-radius: 5px; /* to match Chrome's default select styling */
  border: 1px solid $grey-13;
  box-shadow: none;
  color: $grey-11;
  font-family: $font-light;
  font-weight: normal;
  line-height: normal;
  margin: 0;
  outline: none;
  overflow: auto;
  padding: 6px 8px;
  resize: vertical;
  text-align: left;

  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-shadow: none;

  &.active,
  &:active,
  &.focus {
    border: 1px solid $black-1;
  }
}
