.accordion {
  margin-bottom: 10px;
  &.red {
    background-color: red;
  }
}

.accordion__title {
  font-family: $font-bold;
  padding: 5px 0;
  border-bottom: 2px solid $white-9;
  cursor: pointer;
}

.accordion__title span,
.accordion__title i {
  float: right;
}
