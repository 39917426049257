/**
xs (for phones - screens less than 768px wide)
sm (for tablets - screens equal to or greater than 768px wide)
md (for small laptops - screens equal to or greater than 992px wide)
lg (for laptops and desktops - screens equal to or greater than 1200px wide)
*/

$grid-breakpoint-xs: 0;
$grid-breakpoint-sm: 576px;
$grid-breakpoint-md: 768px;
$grid-breakpoint-lg: 840px;
$grid-breakpoint-xl: 1200px;

.grid__container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
}

.grid__container--no-padding {
  padding: 0;
}

.grid__row {
  position: relative;
  &:before,
  &:after {
    display: table;
    content: ' ';
  }
  &:after {
    clear: both;
  }
  &.fill-space {
    width: 100%;
  }
}

.grid__col--1 {
  width: 8.33333%;
}

.grid__col--2 {
  width: 16.66667%;
}

.grid__col--3 {
  width: 25%;
}

.grid__col--4 {
  width: 33.33333%;
}

.grid__col--5 {
  width: 41.66667%;
}

.grid__col--6 {
  width: 50%;
}

.grid__col--7 {
  width: 58.33333%;
}

.grid__col--8 {
  width: 66.66667%;
}

.grid__col--9 {
  width: 75%;
}

.grid__col--10 {
  width: 83.33333%;
}

.grid__col--11 {
  width: 91.66667%;
}

.grid__col--12 {
  width: 100%;
}

.grid__col--1,
.grid__col--2,
.grid__col--3,
.grid__col--4,
.grid__col--5,
.grid__col--6,
.grid__col--7,
.grid__col--8,
.grid__col--9,
.grid__col--10,
.grid__col--11,
.grid__col--12,
.grid__col--full {
  display: inline-block;
  vertical-align: top;
  min-height: 1px;
  padding: 10px;
  position: relative;
}

.go-up {
  margin-top: -42px;
}

.item--actions {
  margin-top: -35px;
  float: right;
}

.no-padding {
  padding: 0;
}

@media only screen and (max-width: $grid-breakpoint-md) {
  .grid__col--1,
  .grid__col--2,
  .grid__col--3,
  .grid__col--4,
  .grid__col--5,
  .grid__col--6,
  .grid__col--7,
  .grid__col--8,
  .grid__col--9,
  .grid__col--10,
  .grid__col--11,
  .grid__col--12 {
    &:not(.grid__col--never-collapse) {
      width: 100%;
    }
  }
}

@media only screen and (min-width: $grid-breakpoint-md) and (max-width: $grid-breakpoint-lg) {
  .grid__col--1,
  .grid__col--2,
  .grid__col--3,
  .grid__col--4,
  .grid__col--5,
  .grid__col--6,
  .grid__col--7,
  .grid__col--8 {
    &:not(.grid__col--never-collapse) {
      width: 50%;
    }
  }

  .grid__col--9,
  .grid__col--10,
  .grid__col--11,
  .grid__col--12 {
    width: 100%;
  }
}
