.tt-stats {
  background-color: $white-3;
  border: 1px solid $white-6;
  border-radius: 3px;
  display: block;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.tt-stats--vertical {
    flex-direction: column;
    align-items: flex-start;
    background-color: $white-1;

    .tt-stats__transclude {
      margin-top: 8px;
      width: 100%;

      li {
        margin-bottom: 4px;

        a {
          font-family: $font-light;
        }
      }

      ul {
        padding-left: 18px;
      }

      ol {
        counter-reset: item;

        li {
          display: block;

          a {
            width: calc(100% - 24px);
            display: inline-block;
          }
        }

        li:before {
          content: counter(item) '. ';
          counter-increment: item;
          width: 24px;
          display: inline-block;
          font-family: $font-bold;
          font-size: 14px;
        }
      }
    }
  }

  .tt-stats__title {
    font-size: 16px;
    font-family: $font-bold;
    text-transform: uppercase;
  }

  .tt-stats__subtitle {
    font-size: 12px;
  }

  .tt-stats__price {
    float: right;
  }

  &:not(.tt-stats--vertical) .tt-stats__transclude {
    font-size: 24px;
    max-width: 50%;
  }

  &:not(.tt-stats--vertical) .tt-stats__error {
    max-width: 65%;
  }

  .tt-stats__see-all {
    text-align: center;
    margin-top: 8px;

    a {
      font-family: $font-bold;
      font-size: 14px;
      color: $grey-12;
    }
  }

  &.tt-stats--vertical div[tt-inline-spinner] {
    width: 100%;
    text-align: center;
    margin: 12px 0;
  }

  &:not(.tt-stats--vertical) div[tt-inline-spinner] {
    width: 80px;
    text-align: center;
    margin: 0 0;
    height: 34px;

    .xlarge-icon {
      font-size: 32px;
    }
  }
}

.tt-stats__animated-container {
  max-height: 150px;
  transition: max-height 0.5s ease-out;
  overflow: hidden;

  &.ng-hide {
    max-height: 0px;
  }
}
