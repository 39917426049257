.portal {
  min-height: 100%;
  position: relative;
}

.login {
  .portal {
    position: relative;
    overflow: auto;
    &:before {
      content: '';
      position: fixed;
      left: -1%;
      right: 0;
      top: -1%;
      z-index: -1;
      display: block;
      background: #333 url('../images/tommy_logo_mid_grey_20_deg.svg') no-repeat 0 0;
      background-size: cover;
      width: 102%;
      height: 102%;
    }
  }
}

.portal__content {
  margin-left: 10px;
}
