.control__passwordhelper {
  background-color: $white-4;
  @include font-size(0.75);
  @include line-height(1.25);
  padding: 7px;
  position: absolute;
  right: 1px;
  top: 26px;
  height: 32px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.password-strength__disabled {
  display: none;
}

.password-strength-wrapper {
  max-width: 150px;
  background-color: #dbd8d3;
  margin-bottom: 5px;
}

.password-strength-bar {
  display: block;
  transition: width 1s ease;
  width: 0%;
  height: 5px;
}

.password-strength-bar-weak {
  background-color: red;
  width: 33.33%;
}

.password-strength-bar-medium {
  background-color: orange;
  width: 66.66%;
}

.password-strength-bar-strong {
  background-color: #27ae60;
  width: 100%;
}
