.customers__details {
  padding-left: 0px;
}

.customers__toggle {
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
  &:hover {
    border-color: transparent;
  }
  &.active {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    height: 42px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.customers__info {
  clear: both;
  width: 100%;
  background-color: $white-8;
  padding: 20px;
}

.customers__details dt {
  width: 55px;
  font-family: $font-bold;
  float: left;
  &.dt-custom {
    text-transform: none;
    font-size: 1rem;
  }
}

.customers__details .address {
  white-space: pre-line;
}

#editsitePostcode {
  padding: 6px 12px;
}
