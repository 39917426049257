.results {
  margin: 0 0 20px 0;
  position: relative;
  text-align: center;
}

.results--line {
  background-color: $white-7;
  height: 1px;
  position: absolute;
  top: 10px;
  width: 100%;
}

.results--label {
  background-color: $white-4;
  padding: 0 10px;
  position: relative;
  color: $grey-2;
  @include line-height(1.25);
}
