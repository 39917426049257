dd {
  margin-bottom: 12px;
}

.card__list {
  dd {
    float: left;
    @include line-height(1.25);
    margin: 0;
    &.total {
      padding-top: 10px;
      border-top: 3px double $grey-7;
    }
  }
  dd {
    width: 60%;
    padding-left: 20px;
  }
}

.card__list--pricing {
  dd {
    padding: 0;
    text-align: center;
    min-width: inherit;
    width: 100%;
  }
  dd {
    margin: 0;
    background-color: $blue-6;
  }
}
