dt {
  text-transform: uppercase;
  font-family: gilroy-extra-bold;
  font-size: 14px;
  margin: 9px 0 2px;
}

.card__list {
  dt {
    float: left;
    @include line-height(1.25);
    margin: 0;
    padding-bottom: 10px;
    &.total {
      padding-top: 10px;
      border-top: 3px double $grey-7;
    }
  }
  dt {
    clear: left;
    width: 40%;
  }
}

.card__list--pricing {
  dt {
    padding: 0;
    text-align: center;
    min-width: inherit;
    width: 100%;
  }
  dt {
    margin: 0 0 4px 0;
    background-color: $grey-1;
    color: $white-1;
  }
}
